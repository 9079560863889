import { trpc } from '@/utils/trpc';
import { LearningCatalogCards } from './LearningCatalogCards';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { usePageHeading } from '@/contexts/PageHeading/PageHeading';
import { LearningCatalog as LearningCatalogType } from '@/utils/trpc';
import { supabase } from '@/utils/supabase';

import { LearningCatalogFilters } from './LearningCatalogFilters';
import { FilterName, useLearningCatalogFilters } from '@/contexts/Filters';
import EmptyState from '@/components/EmptyState';
import { useAuth } from '@/contexts/Auth/Auth';
import { useNavigate } from 'react-router-dom';
import { protectedPaths } from '@/routes/paths';
import { useFlag } from '@unleash/proxy-client-react';

function applySorting(
  learningCatalog: LearningCatalogType[],
  selectedSortOption: string
) {
  if (selectedSortOption === 'Best Rating') {
    return [
      ...learningCatalog.sort((a, b) => b.averageRating - a.averageRating),
    ];
  } else if (selectedSortOption === 'Most Popular') {
    return [...learningCatalog.sort((a, b) => b.numRatings - a.numRatings)];
  } else if (selectedSortOption === 'Newest') {
    return [
      ...learningCatalog.sort(
        (a, b) =>
          new Date(b.created_at!).getTime() - new Date(a.created_at!).getTime()
      ),
    ];
  } else if (selectedSortOption === 'Oldest') {
    return [
      ...learningCatalog.sort(
        (a, b) =>
          new Date(a.created_at!).getTime() - new Date(b.created_at!).getTime()
      ),
    ];
  }
  return learningCatalog;
}

function applyStatusFilters(
  learningCatalog: LearningCatalogType[],
  selectedFilters: Record<FilterName, Record<string, boolean>>
) {
  const selectedStatuses = Object.keys(selectedFilters.Status).filter((k) => {
    return selectedFilters.Status[k];
  });

  return learningCatalog.filter((program) => {
    if (program.status === 'not_started') {
      return selectedStatuses.includes('not started');
    }

    if (program.status === 'in_progress') {
      return selectedStatuses.includes('started');
    }

    return selectedStatuses.includes('completed');
  });
}

function applyTagFilters(
  learningCatalog: LearningCatalogType[],
  selectedFilters: Record<FilterName, Record<string, boolean>>,
  tagsCount: number
) {
  const selectedTags = Object.keys(selectedFilters.Tags).filter((k) => {
    return selectedFilters.Tags[k];
  });

  if (selectedTags.length === 0) return learningCatalog;

  return learningCatalog.filter((program) => {
    if (program.tags.length === 0 && selectedTags.length === tagsCount) {
      return true;
    }
    const programTagNames = program.tags.map((t) => t.tag?.name);
    return selectedTags.some((tag) => programTagNames.includes(tag));
  });
}

function applyAuthorFilter(
  learningCatalog: LearningCatalogType[],
  selectedFilters: Record<FilterName, Record<string, boolean>>,
  userId: string
) {
  return learningCatalog.filter((program) => {
    if (selectedFilters.Author.me && selectedFilters.Author.propeller) {
      return true;
    }
    if (!selectedFilters.Author.me && selectedFilters.Author.propeller) {
      return program.author_id !== userId;
    }
    return program.author_id === userId;
  });
}

export default function LearningCatalog() {
  const { user } = useAuth();
  const programBuilderEnabled = useFlag('programBuilderEnabled');
  const { setPageHeading } = usePageHeading();
  const nav = useNavigate();
  useEffect(() => {
    setPageHeading('Learning Catalog');

    return () => setPageHeading('');
  }, [setPageHeading]);

  const { selectedSortOption, selectedFilters, tags } =
    useLearningCatalogFilters();

  const {
    data: learningCatalog,
    isLoading,
    error,
  } = trpc.programs.getLearningCatalog.useQuery(undefined);

  const [sortedLearningCatalog, setSortedLearningCatalog] = useState<
    LearningCatalogType[]
  >(learningCatalog ?? []);

  useEffect(() => {
    if (!learningCatalog) return;
    if (!selectedSortOption) return;

    const filterFuncs: Array<
      (programs: LearningCatalogType[]) => LearningCatalogType[]
    > = [
      (programs) => applySorting(programs, selectedSortOption),
      (programs) => applyStatusFilters(programs, selectedFilters),
      (programs) =>
        applyTagFilters(programs, selectedFilters, tags?.length ?? 0),
      (programs: LearningCatalogType[]) =>
        applyAuthorFilter(programs, selectedFilters, user.id),
    ];

    const filteredPrograms = filterFuncs.reduce(
      (programs, filterFunc) => filterFunc(programs),
      learningCatalog
    );

    setSortedLearningCatalog(filteredPrograms);
  }, [
    learningCatalog,
    selectedFilters,
    selectedSortOption,
    tags?.length,
    user.id,
  ]);

  useEffect(() => {
    if (error) {
      toast.error(error?.message);
    }
    if (error?.data?.code === 'UNAUTHORIZED') {
      supabase.auth.signOut();
      return;
    }
  }, [error]);

  return (
    <div className="p-2 max-w-7xl mx-auto pt-16 xs:pt-0 relative">
      {isLoading && (
        <div className="flex justify-center items-center">
          <span className="loading loading-spinner loading-lg mx-auto"></span>
        </div>
      )}
      {!isLoading && programBuilderEnabled && (
        <div className="absolute pt-2 w-full">
          <div className="flex">
            <button
              className="btn btn-primary mx-auto"
              onClick={() => {
                nav(protectedPaths.programs.create);
              }}
            >
              Create
            </button>
          </div>
        </div>
      )}

      {!isLoading && <LearningCatalogFilters />}
      {!isLoading && sortedLearningCatalog?.length === 0 && (
        <EmptyState title={'Nothing to show here!'} description={''} />
      )}

      <div className="overflow-y-auto h-screen pb-60">
        <LearningCatalogCards learningCatalog={sortedLearningCatalog} />
      </div>
    </div>
  );
}
