import { Message } from 'ai';
import Markdown from '@/components/Markdown';
import { useAuth } from '@/contexts/Auth/Auth';

export function UserRow(m: Message) {
  const {
    user: { initials },
  } = useAuth();
  return (
    <div className="chat chat-end">
      <div className="chat-bubble bg-[#E4F2FD] text-blue-midnight">
        <Markdown content={m.content} />
      </div>
      <div className="placeholder avatar">
        <div className="bg-neutral text-neutral-content rounded-full w-8">
          <span className="text-xs">{initials}</span>
        </div>
      </div>
    </div>
  );
}
