import { TextField } from '@/pages/Login/components/TextField';
import { trpc } from '@/utils/trpc';
import { Form, Formik } from 'formik';
import { SelectInput } from '@/components/Select';
import * as Yup from 'yup';
import { memo, useMemo } from 'react';
import { MultiSelectInput } from '@/components/MultiSelectTagsInput';
import {
  VisbilityOptions,
  VisibilityMenuOption,
  BuilderProgram,
  VisbilityOption,
} from '@/utils/types';
import { TextAreaField } from '@/components/TextAreaField';
import { useNavigate } from 'react-router-dom';
import { protectedPaths } from '@/routes/paths';
import { getAuthCookie } from '@/utils/getAuthCookie';
import { useChat } from 'ai/react';
import { useSupabase } from '@/contexts/Supabase';
import { useAuth } from '@/contexts/Auth/Auth';

type ProgramFormValues = {
  program_name: string;
  program_description: string;
  scenario_name: string;
  scenario_description: string;
  scenario_details: string;
  visibility: VisibilityMenuOption;
  selectedTags: { name: string; id: number }[];
};

const CreateProgramSchema = Yup.object().shape({
  program_name: Yup.string().min(10, 'Too Short!').required('Required'),
  program_description: Yup.string().min(10, 'Too Short!').required('Required'),
  scenario_name: Yup.string().min(10, 'Too Short!').required('Required'),
  scenario_description: Yup.string().min(10, 'Too Short!').required('Required'),
  scenario_details: Yup.string().min(10, 'Too Short!').required('Required'),
});

function ConfigureProgram({ program }: { program?: BuilderProgram }) {
  const nav = useNavigate();
  const { conversationUuid } = useSupabase();
  const { user } = useAuth();
  const { append } = useChat({
    id: conversationUuid,
    credentials: 'include',
    api: `${process.env.REACT_APP_API_URL}/builder`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthCookie(),
    },
    body: {
      conversationUuid,
      userId: user.id,
    },
  });
  const initialValues = useMemo(
    () => ({
      selectedTags: [] as {
        name: string;
        id: number;
      }[],
      program_name: program?.program_behavior.name ?? '',
      program_description: program?.program_behavior.description ?? '',
      scenario_name: program?.scenario?.scenario_name ?? '',
      scenario_description: program?.scenario?.scenario_description ?? '',
      scenario_details: program?.scenario?.scenario_details ?? '',
      visibility: program?.metadata?.visibility
        ? program.metadata.visibility
        : VisbilityOptions.at(0)!,
    }),
    [program]
  );

  const { data: tags } = trpc.programs.getTags.useQuery();
  const updateBuilderProgramMutation = trpc.programs.update.useMutation();
  const createProgramFromBuilderMutation =
    trpc.programs.createProgram.useMutation();

  return (
    <div className="px-4 py-2">
      {process.env.REACT_APP_ENV !== 'production' && (
        <span className="text-xs">{program?.conversation_uuid}</span>
      )}
      <div className="bg-white">
        <Formik<ProgramFormValues>
          enableReinitialize={true}
          initialErrors={{}}
          initialValues={initialValues}
          validationSchema={CreateProgramSchema}
          onSubmit={async (values) => {
            if (!program) {
              return;
            }
            createProgramFromBuilderMutation.mutate(
              {
                builder_program: program,
                published: true,
              },
              {
                onSuccess() {
                  nav(protectedPaths.learningCatalog);
                },
                onError(error) {
                  console.error('error', error);
                },
              }
            );
          }}
        >
          {({ values, isValid, dirty, ...rest }) => {
            return (
              <Form className="flex flex-col mt-5 gap-1">
                <div className="relative">
                  <TextField
                    label="Program Name"
                    name="program_name"
                    type="text"
                    placeholder="Program name"
                    autocomplete="new-password"
                    className="pr-20"
                    onBlur={() => {
                      if (!program) {
                        return;
                      }
                      if (
                        values.program_name === program.program_behavior.name
                      ) {
                        return;
                      }
                      append({
                        role: 'user',
                        content: `Update the program name to: "${values.program_name}"`,
                      });
                    }}
                    {...rest}
                  />
                </div>

                <TextAreaField
                  label={'Program Description'}
                  placeholder="Describe your program here"
                  name={'program_description'}
                  errors={rest.errors}
                  touched={rest.touched}
                  className="pr-16 h-60"
                  onBlur={() => {
                    if (!program) {
                      return;
                    }
                    if (
                      values.program_description ===
                      program.program_behavior.description
                    ) {
                      return;
                    }
                    append({
                      role: 'user',
                      content: `Update the program description to: "${values.program_description}"`,
                    });
                  }}
                />

                <TextField
                  label="Scenario Name"
                  name="scenario_name"
                  type="text"
                  placeholder="Scenario name"
                  autocomplete="new-password"
                  className="pr-20"
                  onBlur={() => {
                    if (!program) {
                      return;
                    }
                    if (
                      values.scenario_name === program.scenario.scenario_name
                    ) {
                      return;
                    }
                    append({
                      role: 'user',
                      content: `Update the scenario name to: "${values.scenario_name}"`,
                    });
                  }}
                  {...rest}
                />
                <TextAreaField
                  label={'Scenario Description'}
                  placeholder="Describe scenario here"
                  name={'scenario_description'}
                  errors={rest.errors}
                  touched={rest.touched}
                  className="pr-16"
                  onBlur={() => {
                    if (!program) {
                      return;
                    }
                    if (
                      values.scenario_description ===
                      program.scenario.scenario_description
                    ) {
                      return;
                    }
                    append({
                      role: 'user',
                      content: `Update the scenario description to: "${values.scenario_description}"`,
                    });
                  }}
                />
                <TextAreaField
                  label={'Scenario Details'}
                  placeholder="Describe scenario details here"
                  name={'scenario_details'}
                  errors={rest.errors}
                  touched={rest.touched}
                  className="pr-16"
                  onBlur={() => {
                    if (!program) {
                      return;
                    }
                    if (
                      values.scenario_details ===
                      program.scenario.scenario_details
                    ) {
                      return;
                    }
                    append({
                      role: 'user',
                      content: `Update the scenario details to: "${values.scenario_details}"`,
                    });
                  }}
                />

                <SelectInput
                  label={'Visibility'}
                  options={VisbilityOptions}
                  value={values.visibility}
                  setSelected={function (v: {
                    label: string;
                    value: VisbilityOption;
                  }): void {
                    rest.setFieldValue('visibility', v);
                    if (!program) {
                      return;
                    }
                    updateBuilderProgramMutation.mutate({
                      program: {
                        ...program,
                        metadata: {
                          ...(program.metadata as any),
                          visibility: v,
                        },
                      },
                    });
                  }}
                />
                <MultiSelectInput
                  options={tags ?? []}
                  values={values.selectedTags}
                  onChange={(v) => {
                    rest.setFieldValue('selectedTags', v);
                    if (!program) {
                      return;
                    }
                    updateBuilderProgramMutation.mutate({
                      program: {
                        ...program,
                        metadata: {
                          ...(program.metadata as any),
                          tags: v,
                        },
                      },
                    });
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default memo(ConfigureProgram);
