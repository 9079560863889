import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import CreateProgram from './Panels/CreateProgram';
import ConfigureProgram from './Panels/ConfigureProgram';
import { useSupabase } from '@/contexts/Supabase';
import { memo } from 'react';

function StyledTab({ tabName }: { tabName: string }) {
  return (
    <Tab
      className={({ selected }) =>
        clsx(
          'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
          'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
          selected
            ? 'bg-white text-blue-700 shadow'
            : 'text-gray-800 hover:bg-white/[0.12] hover:text-gray-500'
        )
      }
    >
      {tabName}
    </Tab>
  );
}

function Tabs() {
  return (
    <div className="fixed w-1/2 z-10 bg-white h-16 pt-2 border-b">
      <Tab.List className="max-w-sm mx-auto z-100 flex rounded-xl bg-blue-900/20 p-1">
        <StyledTab tabName="Create" />
        <StyledTab tabName="Configure" />
      </Tab.List>
    </div>
  );
}

function BuildProgramPane() {
  const { program } = useSupabase();

  return (
    <Tab.Group as="div" className="h-[calc(100vh-4rem)] overflow-y-auto">
      <Tabs />
      <Tab.Panels className="mt-[4rem] h-full">
        <Tab.Panel className="h-full">
          <CreateProgram />
        </Tab.Panel>
        <Tab.Panel className="bg-white">
          <ConfigureProgram program={program} />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
}

export default memo(BuildProgramPane);
