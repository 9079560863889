import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { trpc } from '@/utils/trpc';
import { useSupabase } from '@/contexts/Supabase';
import ChatPane from '@/components/ChatPane';
import clsx from 'clsx';
import { usePageHeading } from '@/contexts/PageHeading/PageHeading';
import { Message } from 'ai';
import { useEffectOnce } from 'usehooks-ts';

function Deployment() {
  const [initialMessages, setInitialMessages] = useState<Message[]>([]);
  const { setPageHeading, reset } = usePageHeading();
  const { deploymentId } = useParams();
  const { setConversationUuid, setConversationStage, setSuggestedResponses } =
    useSupabase();
  const { data } = trpc.programDeployments.getProgramDeploymentById.useQuery(
    {
      programDeploymentId: +deploymentId!,
    },
    { enabled: !!deploymentId, refetchOnWindowFocus: false }
  );

  const suggestedResponsesDb = data?.conversation.at(0)?.suggested_responses;

  useEffect(() => {
    if (data?.conversation.at(0)?.conversation_uuid) {
      setConversationUuid(data?.conversation.at(0)?.conversation_uuid);
    }
    if (data?.conversation.at(0)?.state) {
      setConversationStage(data.conversation.at(0)!.state as any);
    }
  }, [data?.conversation, setConversationStage, setConversationUuid]);

  useEffect(() => {
    if (suggestedResponsesDb) {
      setSuggestedResponses((suggestedResponsesDb as string[]) ?? []);
    }
  }, [setSuggestedResponses, suggestedResponsesDb]);

  useEffect(() => {
    setInitialMessages(
      (data?.conversation.at(0)?.conversation_items ?? [])
        .sort(
          (a, b) =>
            new Date(a.created_at ?? 0).getTime() -
            new Date(b.created_at ?? 0).getTime()
        )
        .map((ci) => ({
          id: ci.id.toString(),
          content: ci.content ?? '',
          role: ci.role as 'user' | 'system' | 'assistant' | 'function',
        }))
    );
  }, [data?.conversation]);

  useEffectOnce(() => {
    return () => {
      reset();
      setSuggestedResponses([]);
    };
  });

  useEffect(() => {
    setPageHeading(data?.program?.name ?? '');
  }, [data?.program?.name, setPageHeading]);

  if (!deploymentId) {
    return <></>;
  }

  return (
    <div
      className={clsx(
        'flex flex-col w-full max-w-7xl mx-auto',
        'xs:h-[calc(100vh-4rem)]'
      )}
    >
      <ChatPane
        onRestart={async () => {
          setInitialMessages(initialMessages.slice(0, 2));
        }}
        programDeploymentId={deploymentId}
        initialMessages={initialMessages}
      />
    </div>
  );
}

export default memo(Deployment);
