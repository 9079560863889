import clsx from 'clsx';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { usePageHeading } from '@/contexts/PageHeading/PageHeading';
import { ReactComponent as PropellerLogo } from '@/assets/icons/logo-propeller.svg';
import { MemberAvatarDropdown } from '@/components/MemberAvatarDropdown';
import { protectedPaths } from '@/routes/paths';
import { Link, useLocation } from 'react-router-dom';
import { trpc } from '@/utils/trpc';
import { useAuth } from '@/contexts/Auth/Auth';

export function TopNavAlt() {
  const { pageHeading, pageSubHeading, additionalPageHeadingButtons } =
    usePageHeading();
  const location = useLocation();
  const createPaymentLinkMutation =
    trpc.billing.createInitialPaymentLink.useMutation();
  const { user } = useAuth();

  const navigation = [
    {
      name: 'Learning Catalog',
      to: protectedPaths.learningCatalog,
      current: location.pathname === protectedPaths.learningCatalog,
    },
  ];
  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white z-40">
                  <span className="absolute -inset-0.5" />
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start relative">
                <Link
                  to="/"
                  className="sm:flex flex-shrink-0 items-center hidden z-50"
                >
                  <PropellerLogo />
                  <div className="text-white text-xl font-bold font-airbnb-cereal ml-4 hidden sm:flex md:text-3xl">
                    Propeller.
                  </div>
                </Link>
                <div className="hidden sm:ml-6 lg:flex lg:visible space-x-4">
                  {navigation
                    .filter((item) => !item.current)
                    .map((item) => (
                      <Link
                        to={item.to}
                        key={item.name}
                        className={clsx(
                          item.current
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-3 text-sm font-medium z-10'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                </div>
                <div className="flex flex-col items-center flex-grow fixed inset-x-0 top-3 overflow-x-auto">
                  <h2 className="text-lg font-bold leading-7 text-white sm:truncate sm:text-xl md:text-2xl sm:tracking-tight max-w-lg">
                    {pageHeading}
                  </h2>
                  <p>{pageSubHeading}</p>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* <button
                  type="button"
                  className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                {/* Profile dropdown */}
                {/* <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={clsx(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Your Profile
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={clsx(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Settings
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={clsx(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu> */}
                <div>
                  {additionalPageHeadingButtons?.map((Component, i) => (
                    <div key={i}>{Component}</div>
                  ))}
                </div>

                {process.env.RENDER_APP_ENV !== 'production' &&
                  (user.email.includes('sharpnotions') ||
                    user.email.includes('vityl')) && (
                    <button
                      className="btn btn-neutral relative w-20"
                      onClick={async () => {
                        const paymentLink =
                          await createPaymentLinkMutation.mutateAsync({
                            returnUrl: window.location.origin,
                          });
                        window.location.href = `${paymentLink.url}?prefilled_email=${user?.email}&client_reference_id=${user?.id}`;
                      }}
                    >
                      BUY
                    </button>
                  )}

                <div className="relative ml-3">
                  <MemberAvatarDropdown />
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={Link}
                  to={item.to}
                  className={clsx(
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
