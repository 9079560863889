import { trpc } from '@/utils/trpc';
import { useParams } from 'react-router-dom';
import { LearningCatalogCard } from '../../LearningCatalog/LearningCatalogCard';
import { shapes } from '../../LearningCatalog/shapes';

function ViewProgram() {
  const { programUuid } = useParams();
  const { data: program } = trpc.programs.getProgramByUuid.useQuery(
    {
      programUuid: programUuid!,
    },
    { enabled: !!programUuid }
  );

  return (
    <div className="h-[calc(100vh-4rem)] flex justify-center items-center">
      <div className="max-w-sm mb-[33vh]">
        {!programUuid || !program ? (
          <div className="skeleton w-[25rem] h-[27rem]"></div>
        ) : (
          <LearningCatalogCard program={program}>
            {shapes[0 % Object.keys(shapes).length]}
          </LearningCatalogCard>
        )}
      </div>
    </div>
  );
}

export default ViewProgram;
