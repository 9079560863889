import { useAuth } from '@/contexts/Auth/Auth';

export function MemberAvatar() {
  const {
    user: { initials },
  } = useAuth();
  return (
    <div className="w-8 h-8 bg-blue-300 rounded-full flex flex-col justify-center">
      <div className="text-center text-white text-xs">{initials}</div>
    </div>
  );
}
