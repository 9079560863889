import { ChatRow } from '@/components/ChatPane/ChatRow';
import { useSupabase } from '@/contexts/Supabase';
import { useChat } from 'ai/react';
import { memo } from 'react';
import { scroll } from '@/utils/scroll';
import { ChatInputForm } from '@/components/ChatInputForm';
import { getAuthCookie } from '@/utils/getAuthCookie';
import { PublishProgramPopover } from '@/components/PublishProgramPopover';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

function PreviewProgram() {
  const {
    program,
    conversationUuid,
    suggestedPreviewResponses,
    setSuggestedPreviewResponses,
  } = useSupabase();

  const initialMessages = program?.program_behavior
    .learning_program_introduction
    ? [
        {
          id: '0',
          role: 'assistant' as any,
          content: program?.program_behavior.learning_program_introduction,
        },
      ]
    : [];

  const {
    messages,
    input,
    handleInputChange,
    handleSubmit,
    reload,
    isLoading,
    append,
    setMessages,
  } = useChat({
    initialMessages,
    credentials: 'include',
    onFinish: () => {
      scroll();
    },
    api: `${process.env.REACT_APP_API_URL}/builder/preview`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthCookie(),
    },
    body: {
      program_id: program?.id,
      conversation_uuid: conversationUuid,
    },
  });
  return (
    <div className="h-[calc(100vh-8rem)] overflow-y-auto">
      <div className="fixed w-1/2 z-10 bg-white h-16 py-2 border-b">
        <div className="w-full text-center h-full flex justify-between items-center relative">
          <div className="mx-auto">
            <span className="md:text-md sm:text-sm text-xs font-bold px-4">
              {program?.program_behavior.name} Preview
            </span>
          </div>
          <div className="flex items-center justify-between gap-2 pr-2">
            <button
              className="btn btn-circle btn-outline btn-sm m-0 p-0"
              onClick={() => {
                setMessages(initialMessages);
                setSuggestedPreviewResponses([]);
              }}
            >
              <ArrowPathIcon className="h-5 w-5" />
            </button>
            <div>
              <PublishProgramPopover />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-48 pt-8 px-3 mt-12">
        {messages.map((m, i) => (
          <ChatRow
            key={m.id}
            {...m}
            reload={reload}
            isLoading={isLoading}
            isLatest={i === messages.length - 1}
            hideRegen
            hideTTS
          />
        ))}
      </div>

      <div className="absolute bottom-0 left-0 right-0 bg-white">
        <div className="grid grid-cols-3 gap-2 px-3 pb-2 pt-2">
          {suggestedPreviewResponses?.map((sr, i) => (
            <div
              key={i}
              className="border p-3 text-xs rounded-md hover:bg-slate-100 cursor-pointer"
              onClick={() => append({ role: 'user', content: sr })}
            >
              {sr}
            </div>
          ))}
          {/* {suggestedPreviewResponses.length === 0 &&
            <div
                  key={i}
                  className="border p-3 text-xs rounded-md hover:bg-slate-100 cursor-pointer"
                  onClick={() => append({ role: 'user', content: program?.program_behavior.learning_program_introduction })}
                >
                  {program?.program_behavior.learning_program_introduction}
                </div>
            
            )} */}
        </div>
        <div className="p-4 border-t">
          <ChatInputForm
            input={input}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            placeholder="Message Propeller"
          />
        </div>
      </div>
    </div>
  );
}

export default memo(PreviewProgram);
