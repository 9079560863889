import React from 'react';
import userflow from 'userflow.js';
import ReactDOM from 'react-dom/client';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import './index.css';
import App from './app';

if (process.env.REACT_APP_USERFLOW_TOKEN) {
  userflow.init(process.env.REACT_APP_USERFLOW_TOKEN);
}

if (process.env.REACT_APP_DD_CLIENT_TOKEN) {
  datadogRum.init({
    applicationId: '693d1a60-86d4-4dc7-8bc3-5be402e490aa',
    clientToken: 'pub85f55b167c86f14d2a3c0fcc2c30f89c',
    site: 'datadoghq.com',
    service: 'propeller-ui',
    env: process.env.REACT_APP_ENV,
    version: process.env.RENDER_GIT_COMMIT,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });

  datadogLogs.init({
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'propeller-ui',
    env: process.env.REACT_APP_ENV,
    forwardConsoleLogs: 'all',
    sessionSampleRate: 100,
  });

  datadogRum.startSessionReplayRecording();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
