"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BroadcastEventName = exports.Channel = void 0;
var Channel;
(function (Channel) {
    Channel["MY_CHANNEL"] = "my-channel";
})(Channel || (exports.Channel = Channel = {}));
var BroadcastEventName;
(function (BroadcastEventName) {
    BroadcastEventName["conversationStageDetermined"] = "conversation-stage-determined";
    BroadcastEventName["userSuggestionsLoading"] = "user-suggestions-loading";
    BroadcastEventName["userSuggestionsNotLoading"] = "user-suggestions-not-loading";
    BroadcastEventName["userSuggestions"] = "user-suggestions";
    BroadcastEventName["previewSuggestions"] = "preview-suggestions";
    BroadcastEventName["builderConversationLoading"] = "builder-conversation-loading";
    BroadcastEventName["builderConversationNotLoading"] = "builder-conversation-not-loading";
})(BroadcastEventName || (exports.BroadcastEventName = BroadcastEventName = {}));
// export const CONVERSATION_STAGES = {
//   '1': 'Introduction: Start the conversation by introducing yourself and the learning program. Clearly define the goals and objectives of the learning program. Mention how many scenarios there are and give an estimate of how long it will take to complete the program. Be polite and respectful while keeping the tone of the conversation professional. Your greeting should be welcoming.',
//   '2': 'Scenario Setup: Introduce the scenario. Provide any necessary background information',
//   '3': 'Planning/Strategy: Ask the user to plan or strategize how they will approach the scenario. Ask them to consider the pros and cons of their approach and to think about how they will handle any potential challenges or obstacles that may arise',
//   '4': 'Interaction/Execution: Participants engage in the simulated interaction',
//   '5': 'Challenge/Conflict: Introduce obstacles or challenges into the interaction. Create situations that require problem-solving',
//   '6': 'Decision Points: Key moments where the user must make critical decisions. These decisions should be based on the objectives of the learning program',
//   '7': "Resolution/Feedback: Discuss the outcomes of the interaction. Provide specific and detailed feedback of the user's responses, including an overall summary of how the user performed in the role playing exercise.",
//   '8': "End conversation: It's time to end the conversation as there is nothing else to be said. This stage cannot be entered into until stage 7 has been completed",
// };
// export const CONVERSATION_STAGES_ALT = {
//   '1': 'Introduce: Begin with a quick introduction of the program and its goals, including the expected completion time.',
//   '2': 'Strategize: Provide the user with a brief overview of each scenario and ask them to share their high-level strategy of how to approach the situation.',
//   '3': "Roleplay and Simulation: Bring the scenario to life for the user by engaging them in a role-play exercise with hypothetical characters from the scenario, encouraging them to apply their strategy. Introduce challenges, opportunities, and other situations within the scenario to test the user against the program's learning objectives. When in this conversation stage, roleplay as the character and do not remind them you are an AI. ",
//   '4': "Feedback: When concluding each scenario, provide brief feedback on the user's responses and overall approach.",
//   '5': 'Conclude: After all of the scenarios are complete, summarize results and end the program.',
// };
