import { LearningCatalog, trpc } from '@/utils/trpc';
import { useMemo, useState } from 'react';
import { LinkIcon, TrashIcon } from '@heroicons/react/24/outline';
import { DeleteConfirmationModal } from '@/modals/DeleteConfirmationModal';
import { ActionButton, ActionButtonAction } from '@/components/Button';
import clsx from 'clsx';
import toast from 'react-hot-toast';

const CopyLinkAction = (program: LearningCatalog): ActionButtonAction => {
  return {
    ActionElement: (active) => (
      <div
        className={clsx('p-2 rounded-md', {
          'bg-violet-500 text-white': active,
        })}
        onClick={() => {
          navigator.clipboard.writeText(
            `${window.location.origin}/programs/${program.program_uuid}`
          );
          toast.success('Link copied to clipboard', { position: 'top-center' });
        }}
      >
        <div className="flex gap-4">
          <LinkIcon className="h-5 w-5" />
          <span className="">Copy Link</span>
        </div>
      </div>
    ),
  };
};

const DeleteProgramAction = (
  program: LearningCatalog,
  onClick: () => void
): ActionButtonAction => {
  return {
    ActionElement: (active) => (
      <div
        className={clsx('p-2 rounded-md', {
          'bg-violet-500 text-white': active,
        })}
        onClick={onClick}
      >
        <div className="flex gap-4">
          <TrashIcon className="h-5 w-5" />
          <span className="">Delete Program</span>
        </div>
      </div>
    ),
  };
};

export function ProgramAuthorActions({
  program,
}: {
  program: LearningCatalog;
}) {
  const ctx = trpc.useContext();
  const deleteProgramMutation = trpc.programs.deleteProgram.useMutation();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  const Actions = (): ActionButtonAction[] =>
    useMemo(() => {
      const actions = [
        DeleteProgramAction(program, () =>
          setShowDeleteConfirmationModal(true)
        ),
      ];
      if (program.visibility === 'link') {
        actions.push(CopyLinkAction(program));
      }
      return actions;
    }, []);

  return (
    <>
      <DeleteConfirmationModal
        open={showDeleteConfirmationModal}
        onConfirm={async () => {
          await deleteProgramMutation.mutateAsync({
            programUuid: program.program_uuid,
          });
          ctx.programs.getLearningCatalog.refetch();
          setShowDeleteConfirmationModal(false);
        }}
        onDismiss={function (): void {
          setShowDeleteConfirmationModal(false);
        }}
      />
      <div>
        <ActionButton
          className="w-full bg-slate-100 hover:bg-slate-200 flex justify-center items-center rounded-md h-12"
          actions={Actions()}
        />
      </div>
    </>
  );
}
