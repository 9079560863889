export const protectedPaths = {
  dashboard: '/',
  learningCatalog: '/', // view all published programs
  programs: {
    root: '/programs', // view programs ive created
    create: '/programs/create', // create a program
    success: '/programs/:programUuid/success', // success page after creating a program
    edit: '/programs/:programUuid/edit', // update a program (publish, unpublish, delete)
    view: '/programs/:programUuid', // view program detail
    preview: '/programs/preview/:previewDeploymentId', // preview a program without deploying it
  },
  programDeployments: {
    root: '/program-deployments', // view programs ive deployed
    detail: '/program-deployments/:deploymentId', // view program deployment detail
    summary: '/program-deployments/:deploymentId/summary', // view program deployment summary
  },
};

export const publicPaths = {
  login: {
    root: '/login',
    verify: '/verify',
    forgotPassword: '/login/forgot-password',
    signUp: '/login/sign-up',
    updatePassword: '/update-password',
  },
};

export const paths = {
  ...protectedPaths,
  ...publicPaths,
};
