import { Routes, Route } from 'react-router-dom';

import { protectedPaths, publicPaths } from './paths';
import AppLayout from '@/layouts/AppLayout';
import Login, { Verify } from '@/pages/Login';
import NotFound from '@/pages/404';
import PrivateRoute from './PrivateRoute';
import { ProgramSummary } from '@/pages/App/Programs';

import { DeploymentDetail } from '@/pages/App/ProgramDeployments';
import { LearningCatalog } from '@/pages/App/LearningCatalog';
import { LearningCatalogFiltersProvider } from '@/contexts/Filters';
import { ForgotPasswordView } from '@/pages/Login/Views/ForgotPasswordView';
import { SignUpView } from '@/pages/Login/Views/SignUpView';
import { SignInView } from '@/pages/Login/Views/SignInView';
import { UpdatePasswordView } from '@/pages/Login/Views/UpdatePasswordView';
import { ProgramBuilder } from '@/pages/App/Programs';
import ViewProgram from '@/pages/App/Programs/View/ViewProgram';

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AppLayout withTopNav clean />}>
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path={protectedPaths.dashboard}
            element={
              <LearningCatalogFiltersProvider>
                <LearningCatalog />
              </LearningCatalogFiltersProvider>
            }
          />
          <Route
            path={protectedPaths.learningCatalog}
            element={<LearningCatalog />}
          />

          {/* Program Deployments */}
          <Route
            path={protectedPaths.programDeployments.detail}
            element={<DeploymentDetail />}
          />
          <Route
            path={protectedPaths.programDeployments.summary}
            element={<ProgramSummary />}
          />
        </Route>
        <Route
          path={protectedPaths.programs.create}
          element={<ProgramBuilder />}
        />
        <Route path={protectedPaths.programs.view} element={<ViewProgram />} />
      </Route>

      <Route path="/" element={<AppLayout clean />}>
        <Route path={publicPaths.login.root} element={<Login />}>
          <Route path={'forgot-password'} element={<ForgotPasswordView />} />
          <Route path={'sign-up'} element={<SignUpView />} />
          <Route path={''} element={<SignInView />} />
        </Route>
        <Route
          path={publicPaths.login.updatePassword}
          element={<UpdatePasswordView />}
        />
        <Route path={publicPaths.login.verify} element={<Verify />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
