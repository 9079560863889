import { ReactComponent as Card1Shape } from '@/assets/backgrounds/cards/Card 1.svg';
import { ReactComponent as Card2Shape } from '@/assets/backgrounds/cards/Card 2.svg';
import { ReactComponent as Card3Shape } from '@/assets/backgrounds/cards/Card 3.svg';
import { ReactComponent as Card4Shape } from '@/assets/backgrounds/cards/Card 4.svg';
import { ReactComponent as Card5Shape } from '@/assets/backgrounds/cards/Card 5.svg';
import { ReactComponent as Card6Shape } from '@/assets/backgrounds/cards/Card 6.svg';
import { ReactComponent as Card7Shape } from '@/assets/backgrounds/cards/Card 7.svg';
import { ReactComponent as Card8Shape } from '@/assets/backgrounds/cards/Card 8.svg';
import { ReactComponent as Card9Shape } from '@/assets/backgrounds/cards/Card 9.svg';
import { ReactComponent as Card10Shape } from '@/assets/backgrounds/cards/Card 10.svg';
import { ReactComponent as Card11Shape } from '@/assets/backgrounds/cards/Card 11.svg';
import { ReactComponent as Card12Shape } from '@/assets/backgrounds/cards/Card 12.svg';
import { ReactComponent as Card13Shape } from '@/assets/backgrounds/cards/Card 13.svg';
import { ReactComponent as Card14Shape } from '@/assets/backgrounds/cards/Card 14.svg';
import { ReactComponent as Card15Shape } from '@/assets/backgrounds/cards/Card 15.svg';
import { ReactComponent as Card16Shape } from '@/assets/backgrounds/cards/Card 16.svg';
import { ReactComponent as Card17Shape } from '@/assets/backgrounds/cards/Card 17.svg';
import { ReactComponent as Card18Shape } from '@/assets/backgrounds/cards/Card 18.svg';
import { ReactComponent as Card19Shape } from '@/assets/backgrounds/cards/Card 19.svg';
import { ReactComponent as Card20Shape } from '@/assets/backgrounds/cards/Card 20.svg';
import { ReactComponent as Card21Shape } from '@/assets/backgrounds/cards/Card 21.svg';
import { ReactComponent as Card22Shape } from '@/assets/backgrounds/cards/Card 22.svg';
import { ReactComponent as Card23Shape } from '@/assets/backgrounds/cards/Card 23.svg';
import { ReactComponent as Card24Shape } from '@/assets/backgrounds/cards/Card 24.svg';

export const shapes: Record<number, JSX.Element> = {
  0: <Card1Shape />,
  1: <Card2Shape />,
  2: <Card3Shape />,
  3: <Card4Shape />,
  4: <Card5Shape />,
  5: <Card6Shape />,
  6: <Card7Shape />,
  7: <Card8Shape />,
  8: <Card9Shape />,
  9: <Card10Shape />,
  10: <Card11Shape />,
  11: <Card12Shape />,
  12: <Card13Shape />,
  13: <Card14Shape />,
  14: <Card15Shape />,
  15: <Card16Shape />,
  16: <Card17Shape />,
  17: <Card18Shape />,
  18: <Card19Shape />,
  19: <Card20Shape />,
  20: <Card21Shape />,
  21: <Card22Shape />,
  22: <Card23Shape />,
  23: <Card24Shape />,
};
