import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '@/contexts/Auth/Auth';
import { ReactComponent as PropellerLogo } from '@/assets/icons/logo-propeller.svg';
import { useEffect } from 'react';

export default function Login() {
  let location = useLocation();
  let from = location.state?.from?.pathname || '/';
  const { session } = useAuth();

  if (session) {
    return <Navigate to={from} replace />;
  }

  return (
    <div className="flex h-screen justify-center items-center w-full -mt-16">
      <LoginWrapper>
        <Outlet />
      </LoginWrapper>
    </div>
  );
}

export function LoginWrapper({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const from = params.get('from');

  useEffect(() => {
    if (from) {
      sessionStorage.setItem('from', `${window.location.origin}${from || ''}`);
    }
  });

  return (
    <>
      <div className="flex flex-1 flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex items-center justify-center gap-2">
            <PropellerLogo />
            <div className="text-xl font-bold font-airbnb-cereal hidden sm:flex md:text-3xl">
              Propeller.
            </div>
          </div>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-8 shadow sm:rounded-lg sm:px-12">
            <div>
              {children}
              <p className="text-gray-500 text-sm">
                By logging into Propeller, a product of Vityl Inc., you
                acknowledge and agree to comply with our{' '}
                <a
                  className="link link-primary"
                  href="https://vityl.io/subscription-services-agreement/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  className="link link-primary"
                  href="https://vityl.io/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                . Additionally, you confirm that you are authorized to act on
                your own behalf and on behalf of your organization, if
                applicable.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
