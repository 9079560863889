import { useSupabase } from '@/contexts/Supabase';
import { Popover } from '@headlessui/react';
import { Transition } from '@headlessui/react';
import {
  ComponentPropsWithoutRef,
  Fragment,
  ReactElement,
  useState,
} from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { LightBulbIcon, PowerIcon } from '@heroicons/react/24/outline';

export function MobileSuggestionsDropdown({
  onClick,
  onEnd,
  onRestart,
}: {
  onEnd: () => void;
  onRestart: () => void;
  onClick: (sr: string) => void;
}) {
  const { suggestedResponses, suggestedResponsesEnabled } = useSupabase();

  return (
    <PopoverComponent
      button={
        <LightBulbIcon className="w-6 h-6 mx-2 text-yellow-600"></LightBulbIcon>
      }
      arrowClassName={''}
    >
      <div className="bg-white p-4 gap-y-4 m-2 border rounded-lg">
        {suggestedResponsesEnabled &&
          suggestedResponses.map((sr, i) => (
            <Popover.Button
              key={sr}
              className="bg-blue-100 px-2 py-3 my-2 rounded-lg text-sm text-left"
              onClick={() => onClick(sr)}
            >
              ✨ {sr}
            </Popover.Button>
          ))}
        <div className="flex justify-between">
          <Popover.Button
            className="btn btn-outline btn-xs xs:btn-sm sm:btn-md"
            onClick={onRestart}
          >
            Restart
          </Popover.Button>
          <Popover.Button
            className="btn text-white bg-green-500/70 hover:bg-green-300 border-none btn-xs xs:btn-sm sm:btn-md"
            onClick={onEnd}
          >
            <PowerIcon className="w-4 h-4" />
            <span>End Program</span>
          </Popover.Button>
        </div>
      </div>
    </PopoverComponent>
  );
}

export type Props = ComponentPropsWithoutRef<'div'> & {
  button: ReactElement;
  placement?: Placement;
  arrowClassName: string;
};

function PopoverComponent(props: Props) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: props.placement ?? 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <Popover>
      <Popover.Button ref={setReferenceElement} className="outline-none">
        {props.button}
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className={'z-40'}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {props.children}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
