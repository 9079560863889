import { useState } from 'react';
import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';

import { httpLink } from '@trpc/client';
import AppRoutes from '@/routes';
import { BrowserRouter } from 'react-router-dom';
import { trpc } from '@/utils/trpc';
import { AuthProvider } from '@/contexts/Auth/Auth';
import { SupabaseProvider } from '@/contexts/Supabase';
import { PageHeadingProvider } from '@/contexts/PageHeading/PageHeading';
import { FlagProvider } from '@unleash/proxy-client-react';
import { getAuthCookie } from '../utils/getAuthCookie';

const config = {
  url: process.env.REACT_APP_FLAGGER_PROXY_URL ?? '',
  clientKey: process.env.REACT_APP_FLAGGER_PROXY_CLIENT_KEY ?? '',
  refreshInterval: 120,
  appName: 'propeller-web',
  environment: process.env.REACT_APP_ENV,
  disableMetrics: true,
};

export const queryClient = new QueryClient();

function App() {
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpLink({
          url: `${process.env.REACT_APP_API_URL}/trpc`,
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: 'include',
              headers: {
                Authorization: getAuthCookie(),
              },
            });
          },
        }),
      ],
    })
  );

  return (
    <FlagProvider config={config}>
      <SupabaseProvider>
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <PageHeadingProvider>
                <BrowserRouter>
                  <AppRoutes />
                </BrowserRouter>
              </PageHeadingProvider>
            </AuthProvider>
          </QueryClientProvider>
        </trpc.Provider>
        <Toaster
          toastOptions={{
            position: 'bottom-right',
          }}
        />
      </SupabaseProvider>
    </FlagProvider>
  );
}

export default App;
