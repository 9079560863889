import { Database } from '@propeller/shared-types';

export type VisbilityOption = Exclude<
  Database['public']['Enums']['program_visibility'],
  'public' | 'group'
>;

export type VisibilityMenuOption = { label: string; value: VisbilityOption };

export const VisbilityOptions: VisibilityMenuOption[] = [
  { label: 'Private', value: 'private' },
  { label: 'Link Only', value: 'link' },
];

type BaseProgram = Database['public']['Tables']['builder_conversations']['Row'];
type BuilderProgramBehavior = Exclude<
  BaseProgram['program_behavior'],
  'Json'
> & {
  name: string;
  description: string;
  context: string;
  learning_program_introduction?: string;
};

export type BuilderProgram = BaseProgram & {
  program_behavior: BuilderProgramBehavior;
  scenario?: {
    scenario_name?: string;
    scenario_details?: string;
    scenario_description?: string;
  };
  metadata?: {
    visibility?: VisibilityMenuOption;
  };
};
