import { memo } from 'react';
import { ReactComponent as PropellerLogo } from '@/assets/icons/logo-propeller.svg';
import { ReactComponent as HomeIcon } from '@/assets/icons/logo-home.svg';
import { Link } from 'react-router-dom';
import { protectedPaths } from '@/routes/paths';

function Nav() {
  return (
    <nav className="w-72 bg-blue-950 min-h-screen">
      <div className="flex p-7">
        <PropellerLogo />
        <span className="font-serif text-4xl text-[#79F5BB] pl-2 font-semibold">
          Propeller
        </span>
      </div>
      <div className="flex flex-col p-4">
        <ul>
          <li>
            <div className="flex gap-4">
              <HomeIcon />
              <h2 className="text-white font-bold">
                <Link to={protectedPaths.learningCatalog}>
                  Learning Catalog
                </Link>
              </h2>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default memo(Nav);
