import { trpc } from '@/utils/trpc';
import clsx from 'clsx';
import { LearningCatalog as LearningCatalogType } from '@/utils/trpc';
import { StarIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { ProgramStartingModal } from './ProgramStartingModal';
import { LearningCatalogCardActions } from './LearningCatalogCardActions';
import { LinkIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { useAuth } from '@/contexts/Auth/Auth';

const MAX_DESCRIPTION_LENGTH = 200;

export function getTruncatedText(s: string, cardExpanded: boolean) {
  if (cardExpanded) {
    return `${s} `;
  }
  const firstPunctuation = Math.max(s.indexOf('!') + 1, s.indexOf('.') + 1);
  if (firstPunctuation === 0) {
    return s.length > MAX_DESCRIPTION_LENGTH
      ? `${s.substring(0, MAX_DESCRIPTION_LENGTH)}... `
      : s;
  }
  return `${s.substring(
    0,
    Math.min(MAX_DESCRIPTION_LENGTH, firstPunctuation)
  )} `;
}

export function LearningCatalogCard({
  program,
  children,
}: {
  program: LearningCatalogType;
  children: JSX.Element | undefined;
}) {
  const { user } = useAuth();
  const deployProgramMutation = trpc.programs.deploy.useMutation();
  const [cardExpanded, setCardExpanded] = useState(false);
  const [showProgramStartingModal, setShowProgramStartingModal] =
    useState(false);

  const truncatedText = getTruncatedText(program.description, cardExpanded);

  return (
    <>
      <ProgramStartingModal
        isOpen={showProgramStartingModal || deployProgramMutation.isLoading}
        closeModal={() => setShowProgramStartingModal(false)}
      />

      <div className="card shadow-none border-blue-300 border-opacity-20 border bg-base-100 hover:border-[#88C9F9] hover:border">
        {children}
        <div className="card-body min-h-[20rem]">
          <div className="flex items-center justify-between">
            <span className="text-sky-900 font-bold  leading-normal card-title relative">
              {program.name}
            </span>
            {program.author_id === user.id &&
              program.visibility === 'private' && (
                <div
                  className={clsx('tooltip tooltip-left z-40', {})}
                  data-tip={`This program is private and can only be accessed by you.`}
                >
                  <LockClosedIcon className="h-5 w-5 text-gray-500" />
                </div>
              )}
            {program.author_id === user.id && program.visibility === 'link' && (
              <div
                className={clsx('tooltip tooltip-left z-40', {})}
                data-tip={`This program is viewable by anyone with the link.`}
              >
                <LinkIcon className="h-5 w-5 text-gray-500" />
              </div>
            )}
          </div>
          {program.numRatings > 0 && (
            <div className="flex items-center">
              {[0, 1, 2, 3, 4].map((rating) => (
                <StarIcon
                  key={rating}
                  className={clsx(
                    program.averageRating > rating
                      ? 'text-yellow-400'
                      : 'text-gray-200',
                    'h-5 w-5 flex-shrink-0'
                  )}
                  aria-hidden="true"
                />
              ))}
            </div>
          )}
          {program.tags.length > 0 && (
            <div className="flex items-center gap-2 flex-wrap">
              {program.tags.map((t) => (
                <div className="badge badge-outline" key={t.tag?.name}>
                  {t.tag?.name}
                </div>
              ))}
            </div>
          )}
          <p>
            <span className="opacity-50 text-sky-900 text-sm">
              {truncatedText}
            </span>
            {program.description.length > MAX_DESCRIPTION_LENGTH && (
              <button>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setCardExpanded((isOpen) => !isOpen);
                  }}
                  className="text-teal-regular text-sm font-bold"
                >
                  {cardExpanded ? 'See less ' : 'See more'}
                </span>
              </button>
            )}
          </p>
          <div className="card-actions justify-between items-center">
            <LearningCatalogCardActions program={program} />
          </div>
        </div>
      </div>
    </>
  );
}
