import { SignInLink } from '../components/SignInLink';
import { supabase } from '@/utils/supabase';
import { LoginWrapper } from '../Login';
import { useNavigate } from 'react-router-dom';
import { protectedPaths } from '@/routes/paths';
import { Formik, FormikValues, Form } from 'formik';
import { TextField } from '../components/TextField';
import toast from 'react-hot-toast';

export function UpdatePasswordView() {
  const nav = useNavigate();

  return (
    <LoginWrapper>
      <Formik
        initialValues={{
          password: '',
        }}
        onSubmit={async (values: FormikValues) => {
          const { error: updateUserError } = await supabase.auth.updateUser({
            password: values.password,
          });
          if (updateUserError) {
            toast.error(updateUserError.message);
          } else {
            nav(protectedPaths.dashboard);
          }
        }}
      >
        {({ values, isValid, ...rest }) => (
          <Form className="flex flex-col mt-5 gap-1">
            <div className="space-y-4">
              <TextField
                name="password"
                type="password"
                label="New Password"
                autocomplete="new-password"
                placeholder="Your new password"
                {...rest}
              />

              <div className="flex flex-col">
                <button type="submit" className="btn btn-primary btn-block">
                  Update Password
                </button>
                <SignInLink />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </LoginWrapper>
  );
}
