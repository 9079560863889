import { Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Popover } from '@headlessui/react';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { useSupabase } from '@/contexts/Supabase';
import { trpc } from '@/utils/trpc';
import { VisbilityOptions } from '@/utils/types';
import { useNavigate } from 'react-router-dom';
import { protectedPaths } from '@/routes/paths';

export function PublishProgramPopover() {
  const nav = useNavigate();
  const { program } = useSupabase();
  const updateBuilderProgramMutation = trpc.programs.update.useMutation();
  const createProgramFromBuilderMutation =
    trpc.programs.createProgram.useMutation();

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button className={`btn btn-outline btn-md flex-nowrap`}>
            <span>Publish</span>
            <ChevronDownIcon
              className={`${open ? 'text-gray-400' : 'text-gray-900/70'}
                  ml-1 h-4 w-4 transition duration-150 ease-in-out group-hover:text-gray-300/80 `}
              aria-hidden="true"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="bg-white absolute left-1/2 z-10 mt-3 mr-3 w-screen max-w-[16rem] -translate-x-[75%] transform px-4 sm:px-0">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                <Formik
                  initialValues={{
                    radio: program?.metadata.visibility?.value ?? 'link',
                  }}
                  onSubmit={function (
                    values: FormikValues,
                    formikHelpers: FormikHelpers<FormikValues>
                  ): void | Promise<any> {
                    throw new Error('Function not implemented.');
                  }}
                >
                  {({ values }) => (
                    <Form>
                      <div className="relative grid gap-4 bg-white p-7">
                        <span>Program Visibility:</span>
                        {VisbilityOptions.map((option) => (
                          <div className="form-control" key={option.value}>
                            <label className="label cursor-pointer">
                              <Field
                                type="radio"
                                name="radio"
                                value={option.value}
                                className="radio"
                                onBlur={() => {
                                  if (!program) {
                                    return;
                                  }
                                  updateBuilderProgramMutation.mutate({
                                    program: {
                                      ...program,
                                      metadata: {
                                        visibility: option,
                                      },
                                    },
                                  });
                                }}
                              />
                              <span className="label-text w-full ml-5">
                                {option.label}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                      <div className="p-2">
                        <button
                          className="btn btn-primary w-full"
                          type="button"
                          disabled={!program}
                          onClick={() => {
                            if (!program) {
                              return;
                            }
                            createProgramFromBuilderMutation.mutate(
                              {
                                builder_program: {
                                  ...program,
                                  metadata: {
                                    visibility: {
                                      label: values.radio,
                                      value: values.radio,
                                    },
                                  },
                                },
                                published: true,
                              },
                              {
                                onSuccess: () => {
                                  nav(protectedPaths.learningCatalog);
                                },
                              }
                            );
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
