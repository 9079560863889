import { protectedPaths } from '@/routes/paths';
import {
  ArrowLeftIcon,
  PaperAirplaneIcon,
  PowerIcon,
} from '@heroicons/react/24/outline';
import { Message } from 'ai';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { ChatRow } from './ChatRow';
import { useEffect, useState } from 'react';
import { useSupabase } from '@/contexts/Supabase';
import { useChat } from 'ai/react';
import { scroll } from '@/utils/scroll';
import { trpc } from '@/utils/trpc';

import { ForceCompletionModal } from '@/modals';

import { ChatSettingsDropdown } from './ChatSettingsDropdown';
import { MobileSuggestionsDropdown } from './MobileSuggestionsDropdown';

export default function ChatPaneAlt({
  programDeploymentId,
  initialMessages = [],
  onRestart,
}: {
  programDeploymentId: string;
  initialMessages?: Message[];
  onRestart?: () => void;
}) {
  const [forceCompletion, setForceCompletion] = useState(false);
  const [showForceCompletionModal, setShowForceCompletionModal] =
    useState(false);
  const nav = useNavigate();
  const {
    conversationUuid,
    suggestedResponses,
    setConversationUuid,
    reset,
    suggestedResponsesLoading,
    suggestedResponsesEnabled,
  } = useSupabase();
  const endChatMutation = trpc.chat.endChat.useMutation();
  const restartConversationMutation =
    trpc.chat.restartConversation.useMutation();

  async function endChat() {
    stop();
    const result = await endChatMutation.mutateAsync({
      programDeploymentId: +programDeploymentId,
      conversationUuid: conversationUuid ?? '',
      forceCompletion,
    });
    if (!result.success) {
      setShowForceCompletionModal(true);
      return;
    }

    setConversationUuid(undefined);
    reset();

    nav(
      generatePath(protectedPaths.programDeployments.summary, {
        deploymentId: programDeploymentId,
      })
    );
  }

  async function restartChat() {
    stop();
    await restartConversationMutation.mutateAsync({
      conversationUuid: conversationUuid ?? '',
    });

    reset();
    onRestart?.();
    setMessages(initialMessages.slice(0, 2));
  }

  const {
    messages,
    input,
    handleInputChange,
    handleSubmit,
    stop,
    append,
    setMessages,
    reload,
    isLoading,
  } = useChat({
    id: conversationUuid,
    initialMessages,
    onFinish: () => {
      scroll();
    },
    body: {
      conversationUuid,
    },
    api: `${process.env.REACT_APP_API_URL}/completion`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  useEffect(() => {
    scroll();
  }, [messages]);

  return (
    <>
      <ForceCompletionModal
        open={showForceCompletionModal}
        onConfirm={async () => {
          setForceCompletion(true);
          setShowForceCompletionModal(false);

          await endChatMutation.mutateAsync({
            programDeploymentId: +programDeploymentId,
            conversationUuid: conversationUuid ?? '',
            forceCompletion: true,
          });
          setConversationUuid(undefined);
          reset();

          nav(protectedPaths.learningCatalog);
        }}
        onDismiss={() => {
          setShowForceCompletionModal(false);
        }}
      />
      <div className="h-full pt-16 sm:pt-0 flex flex-col xs:relative">
        <div className="sm:h-[8vh] sm:flex justify-between items-center py-2 hidden">
          <Link
            to={protectedPaths.learningCatalog}
            className="flex flex-row gap-3 z-40"
          >
            <ArrowLeftIcon className="w-6 h-6 text-blue-800 ml-4" />
            <span>Learning Catalog</span>
          </Link>
          <div className="space-x-4 z-10">
            <button
              className="btn btn-outline btn-xs xs:btn-sm sm:btn-md"
              type="button"
              onClick={restartChat}
            >
              Restart
            </button>
            <button
              className="btn text-white bg-green-500/70 hover:bg-green-300 border-none btn-xs xs:btn-sm sm:btn-md"
              type="button"
              onClick={endChat}
            >
              <PowerIcon className="w-4 h-4" />
              <span>End Program</span>
            </button>
          </div>
        </div>
        <div className="bg-white xs:-mt-12 sm:mt-0 xs:h-[82vh] h-[67vh] sm:h-[53vh] border border-gray-100 rounded-lg overflow-y-auto p-6">
          {messages.map((m, i) => (
            <ChatRow
              key={m.id}
              {...m}
              reload={reload}
              isLoading={isLoading}
              isLatest={i === messages.length - 1}
            />
          ))}
        </div>
        <div className="absolute bottom-0 flex flex-col justify-between left-0 right-0 sm:h-[30vh] pb-3">
          <div className="flex">
            <div className="my-auto block sm:hidden">
              <MobileSuggestionsDropdown
                onEnd={endChat}
                onRestart={restartChat}
                onClick={(sr) => {
                  append({ role: 'user', content: sr });
                  reset();
                }}
              />
            </div>
            <form
              onSubmit={(e) => {
                handleSubmit(e);
                reset();
              }}
              className="border border-slate-300 rounded-md flex flex-grow justify-between items-center"
            >
              <input
                className="w-full h-10 p-2 my-2 outline-none bg-inherit"
                value={input ?? ''}
                placeholder="Write your message"
                onChange={handleInputChange}
              />
              {input === '' && (
                <div className="sm:flex gap-2 p-2 hidden">
                  {['Yes', 'No', 'Go back', 'Skip forward', 'Help'].map(
                    (standardResp) => (
                      <div
                        key={standardResp}
                        className="bg-blue-100 px-3 py-3 rounded-lg hover:bg-green-100 text-sm whitespace-nowrap"
                        onClick={() => {
                          append({ role: 'user', content: standardResp });
                        }}
                      >
                        {standardResp}
                      </div>
                    )
                  )}
                </div>
              )}
              <button>
                <PaperAirplaneIcon className="w-6 h-6 -rotate-45 text-green-800 mr-4" />
              </button>
            </form>
            <div className="my-auto sm:block hidden">
              <ChatSettingsDropdown />
            </div>
          </div>

          {suggestedResponsesEnabled && suggestedResponsesLoading && (
            <div className="sm:flex justify-center items-center flex-grow hidden">
              <span className="loading loading-dots loading-lg"></span>
            </div>
          )}
          {suggestedResponsesEnabled &&
            suggestedResponses.map((sr, i) => (
              <div
                key={sr}
                className="bg-blue-100 px-2 py-3 rounded-lg hover:bg-green-100 text-sm hidden sm:block"
                onClick={() => {
                  append({ role: 'user', content: sr });
                  reset();
                }}
              >
                ✨ {sr}
              </div>
            ))}
          {!suggestedResponsesEnabled && (
            <div className="sm:flex justify-center items-center flex-grow hidden">
              <span className="text-gray-400 text-sm">
                Suggested responses are turned off. Click the{' '}
                <span className="text-2xl">⚙ </span>
                gear icon to the right of the chat input box to turn them back
                on.
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
