import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '@/contexts/Auth/Auth';

function PrivateRoute() {
  let auth = useAuth();
  let location = useLocation();
  let from = location.state?.from?.pathname || '/';

  if (!auth.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from }} replace />;
    // return <div>NOT LOGGED IN!</div>;
  }

  return <Outlet />;
}

export default PrivateRoute;
