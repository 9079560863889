import { Fragment, useEffect } from 'react';
import { Menu, Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Filter, useLearningCatalogFilters } from '@/contexts/Filters';
import clsx from 'clsx';
import {
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikValues,
  useFormikContext,
} from 'formik';

function Crap({ section }: { section: Filter }) {
  const { values, setValues } = useFormikContext();
  const { setSelectedFilters } = useLearningCatalogFilters();
  useEffect(() => {
    setSelectedFilters(values as any);
  }, [setSelectedFilters, values]);
  return (
    <button
      className={clsx(
        {
          hidden: section.id !== 'tags',
        },
        'btn btn-xs mt-4 btn-neutral'
      )}
      onClick={() => {
        if (section.id === 'tags') {
          setValues({
            ...(values as any),
            Tags: section.options
              .map((o) => ({ [o.value]: true }))
              .reduce(
                (p, c) => ({
                  ...p,
                  ...c,
                }),
                {}
              ),
          });
        }
      }}
    >
      select all
    </button>
  );
}

export function LearningCatalogFilters() {
  const {
    sortOptions,
    selectedSortOption,
    setSelectedSortOption,
    filters,
    selectedFilters,
  } = useLearningCatalogFilters();
  return (
    <div className="mx-auto max-w-3xl px-4 text-center sm:px-6 lg:max-w-7xl lg:px-8">
      <section
        aria-labelledby="filter-heading"
        className="border-b border-gray-200 py-2 sm:py-6"
      >
        <div className="flex items-center justify-between">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                Sort
                <ChevronDownIcon
                  className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {sortOptions.map((option) => (
                    <Menu.Item key={option}>
                      {({ active }) => (
                        <span
                          onClick={() => setSelectedSortOption(option)}
                          className={clsx(
                            active ? 'bg-gray-100' : '',
                            { 'bg-green-100': selectedSortOption === option },
                            'block px-4 py-2 text-sm font-medium text-gray-900'
                          )}
                        >
                          {option}
                        </span>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          <Popover.Group className="flex sm:items-baseline space-x-4 focus:outline-none sm:space-x-8">
            <Formik
              initialValues={selectedFilters}
              onSubmit={function (
                values: FormikValues,
                formikHelpers: FormikHelpers<FormikValues>
              ): void | Promise<any> {
                throw new Error('Function not implemented.');
              }}
            >
              {({ handleChange }) => {
                return filters.map((section, sectionIdx) => (
                  <Popover
                    as="div"
                    key={section.name}
                    id={`desktop-menu-${sectionIdx}`}
                    className="relative inline-block text-left"
                  >
                    <div>
                      <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        <span>{section.name}</span>
                        <ChevronDownIcon
                          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Popover.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Form className="space-y-4">
                          {section.options.map((option, optionIdx) => (
                            <Field
                              key={option.value}
                              name={`${section.name}.${option.value}`}
                              type="checkbox"
                            >
                              {({ field }) => (
                                <div
                                  key={option.value}
                                  className="flex items-center"
                                >
                                  <input
                                    {...field}
                                    onChange={handleChange}
                                    // defaultChecked={field.value}
                                    value={field.value}
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-${section.id}-${optionIdx}`}
                                    className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              )}
                            </Field>
                          ))}
                        </Form>
                        <Crap section={section} />
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                ));
              }}
            </Formik>
          </Popover.Group>
        </div>
      </section>
    </div>
  );
}
