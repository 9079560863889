import { GoogleAuth } from '../components/GoogleAuth';
import { SignInLink } from '../components/SignInLink';
import { supabase } from '@/utils/supabase';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '../components/TextField';
import toast from 'react-hot-toast';

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(6, 'Too Short!').required('Required'),
  name: Yup.string().min(2, 'Too Short!').required('Required'),
});

export function SignUpView() {
  return (
    <>
      <GoogleAuth />
      <Formik
        initialValues={{
          email: '',
          password: '',
          name: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={async (values) => {
          const { error: signUpError } = await supabase.auth.signUp({
            email: values.email,
            password: values.password,
            options: {
              data: {
                name: values.name,
              },
            },
          });
          if (signUpError) {
            toast.error(signUpError.message);
          }
        }}
      >
        {({ values, isValid, ...rest }) => (
          <Form className="flex flex-col mt-5 gap-1">
            <TextField
              label="Email address"
              name="email"
              placeholder="Your email address"
              type="email"
              autocomplete="email"
              {...rest}
            />
            <TextField
              label="Create a Password"
              name="password"
              placeholder="Your password"
              type="password"
              autocomplete="new-password"
              {...rest}
            />
            <TextField
              label="Name"
              name="name"
              placeholder="Your name"
              autocomplete="name"
              {...rest}
            />

            <div className="flex flex-col pt-4">
              <button
                disabled={
                  !isValid || !values.email || !values.password || !values.name
                }
                type="submit"
                className="btn btn-primary"
              >
                Sign up
              </button>
              <SignInLink />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
