import { trpc } from '@/utils/trpc';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

export function RatingForm() {
  const { deploymentId } = useParams();
  const { data: existingRating } = trpc.programDeployments.getRating.useQuery(
    {
      deploymentId: +deploymentId!,
    },
    {
      enabled: !!deploymentId,
    }
  );
  const saveRatingMutation = trpc.programDeployments.saveRating.useMutation();

  return (
    <Formik
      enableReinitialize
      initialValues={{ rating: existingRating?.toString() ?? '0' }}
      onSubmit={async function (
        values: FormikValues,
        formikHelpers: FormikHelpers<FormikValues>
      ) {
        await saveRatingMutation.mutateAsync({
          deploymentId: +deploymentId!,
          rating: +values.rating,
        });
        toast.success('Rating saved');
      }}
    >
      {({ values, submitForm }) => (
        <Form>
          <div>Program Rating</div>
          <div role="group">
            <div className="rating rating-lg">
              <Field
                type="radio"
                name="rating"
                value={'0'}
                className="rating-hidden"
                onClick={submitForm}
              />
              <Field
                type="radio"
                name="rating"
                value={'1'}
                className="mask mask-star-2"
                onClick={submitForm}
              />
              <Field
                type="radio"
                name="rating"
                value={'2'}
                className="mask mask-star-2"
                onClick={submitForm}
              />
              <Field
                type="radio"
                name="rating"
                value={'3'}
                className="mask mask-star-2"
                onClick={submitForm}
              />
              <Field
                type="radio"
                name="rating"
                value={'4'}
                className="mask mask-star-2"
                onClick={submitForm}
              />
              <Field
                type="radio"
                name="rating"
                value={'5'}
                className="mask mask-star-2"
                onClick={submitForm}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
