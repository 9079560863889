export function scroll(
  block: 'end' | 'start' | 'center' | 'nearest' = 'start'
) {
  document
    ?.querySelectorAll('.chat-bubble')
    [document?.querySelectorAll('.chat-bubble').length - 1]?.scrollIntoView({
      behavior: 'smooth',
      block,
      inline: 'end',
    });
}
