import Nav from '@/components/Nav/Nav';
import { TopNavAlt } from '@/components/TopNavAlt';
import clsx from 'clsx';
import { ComponentPropsWithoutRef, memo } from 'react';
import { Outlet } from 'react-router-dom';

import StickyBox from 'react-sticky-box';

type Props = ComponentPropsWithoutRef<'div'> & {
  clean?: boolean;
  withTopNav?: boolean;
};

function AppLayout({
  className,
  children,
  style,
  clean,
  withTopNav,
  ...props
}: Props) {
  return (
    <div
      id="app-layout"
      className={clsx(className, 'flex')}
      style={style}
      {...props}
    >
      {!clean && (
        <StickyBox className="flex h-screen flex-col items-center">
          <Nav />
        </StickyBox>
      )}
      <main
        className={clsx('flex flex-grow', {
          'rounded-tl-xl rounded-bl-xl': !clean,
        })}
      >
        <div className="w-full sm:bg-[#F9FCFF] overflow-hidden xs:h-screen  sm:h-screen">
          {withTopNav && (
            <div className="fixed left-0 right-0 top-0 bg-white z-40">
              <TopNavAlt />
            </div>
          )}
          <div className="xs:pt-16">
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  );
}

export default memo(AppLayout);
