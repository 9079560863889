import { GoogleAuth } from '../components/GoogleAuth';
import { ForgotPasswordLink } from '../components/ForgotPasswordLink';
import { SignUpLink } from '../components/SignUpLink';
import { supabase } from '@/utils/supabase';
import { Form, Formik } from 'formik';
import { TextField } from '../components/TextField';
import toast from 'react-hot-toast';

export function SignInView() {
  return (
    <>
      <GoogleAuth />
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={async (values) => {
          const { error: signInError } = await supabase.auth.signInWithPassword(
            values
          );
          if (signInError) {
            toast.error(signInError.message);
          }
        }}
      >
        {({ values, isValid, ...rest }) => (
          <Form className="flex flex-col mt-5 gap-0">
            <TextField
              label="Email address"
              name="email"
              type="email"
              placeholder="Your email address"
              autocomplete="email"
              {...rest}
            />
            <TextField
              label="Password"
              name="password"
              type="password"
              placeholder="Your password"
              autocomplete="current-password"
              {...rest}
            />

            <div>
              <button
                type="submit"
                disabled={!values.email || !values.password || !isValid}
                className="btn btn-primary btn-block"
              >
                Sign in
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="flex flex-col justify-center w-full items-center py-2.5">
        <ForgotPasswordLink />
        <SignUpLink />
      </div>
    </>
  );
}
