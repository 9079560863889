import { trpc } from '@/utils/trpc';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { protectedPaths } from '@/routes/paths';
import { useAuth } from '@/contexts/Auth/Auth';
import { ProgramAuthorActions } from './ProgramAuthorActions';

export function LearningCatalogCardActions({ program }) {
  const { user } = useAuth();
  const deployProgramMutation = trpc.programs.deploy.useMutation();
  const nav = useNavigate();

  return (
    <>
      {['not_started', 'completed'].includes(program.status) && (
        <button
          className="btn btn-outline uppercase"
          disabled={deployProgramMutation.isLoading}
          onClick={async () => {
            const { programDeployment } =
              await deployProgramMutation.mutateAsync({
                programUuid: program.program_uuid,
              });

            nav(
              generatePath(protectedPaths.programDeployments.detail, {
                deploymentId: programDeployment.id,
              })
            );
          }}
        >
          {program.status === 'not_started' ? 'Start' : 'Restart'}
        </button>
      )}
      {program.status === 'in_progress' && (
        <button
          className="btn btn-outline uppercase"
          disabled={deployProgramMutation.isLoading}
          onClick={async () => {
            nav(
              generatePath(protectedPaths.programDeployments.detail, {
                deploymentId: program.deploymentId,
              })
            );
          }}
        >
          Continue
        </button>
      )}
      {program.status === 'completed' && (
        <Link
          to={generatePath(protectedPaths.programDeployments.summary, {
            deploymentId: program.deploymentId,
          })}
        >
          <button className="btn btn-outline uppercase">View Evaluation</button>
        </Link>
      )}
      <>
        {program.author_id === user.id && (
          <ProgramAuthorActions program={program} />
        )}
      </>
    </>
  );
}
