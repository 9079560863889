import Cookies from 'js-cookie';

function bytesToBase64(bytes) {
  const binString = String.fromCodePoint(...bytes);
  return btoa(binString);
}

export function strToBase64(str: string) {
  return bytesToBase64(new TextEncoder().encode(str));
}

export function getAuthCookie() {
  const cookie = Cookies.get('supabase_session');
  const sessionCookie = JSON.parse(cookie ?? '{}');
  const base64Session = strToBase64(JSON.stringify(sessionCookie));
  return `Bearer ${base64Session}`;
}
