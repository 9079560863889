import { useNavigate } from 'react-router-dom';

export function ForgotPasswordLink() {
  const nav = useNavigate();
  return (
    <button
      className="btn btn-sm btn-link normal-case decoration-gray-500"
      onClick={() => {
        nav('/login/forgot-password');
      }}
    >
      <span className="text-gray-500 font-normal">Forgot your password?</span>
    </button>
  );
}
