import clsx from 'clsx';
import { Label } from './Label';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { InputErrorLabel } from './InputErrorLabel';

const BaseTextInputStyles = `block input placeholder:text-gray-400 py-1.5 rounded-md sm:leading-6 sm:text-sm text-gray-900 w-full`;

const ConditionalTextInputStyles = <T,>(
  errors: FormikErrors<T>,
  touched: FormikTouched<T>,
  fieldName: string
) => ({
  'input-error': errors[fieldName] && touched[fieldName],
  'ring-1 ring-gray-300 ring-inset shadow-sm border-0':
    !errors[fieldName] || !touched[fieldName],
});

export const TextField = <T,>({
  label,
  name,
  errors,
  touched,
  placeholder,
  type = 'text',
  autocomplete,
  className,
  onBlur,
}: {
  label: string;
  name: string;
  type?: string;
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
  placeholder: string;
  autocomplete?: string;
  className?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}) => (
  <div>
    <Label label={label} htmlFor={name} />
    <Field
      name={name}
      type={type}
      placeholder={placeholder}
      className={clsx(
        BaseTextInputStyles,
        ConditionalTextInputStyles(errors, touched, name),
        className
      )}
      autoComplete={autocomplete}
      onBlur={onBlur}
    />
    <InputErrorLabel errors={errors} touched={touched} name={name} />
  </div>
);
