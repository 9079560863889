import { useNavigate } from 'react-router-dom';

export function SignInLink() {
  const nav = useNavigate();
  return (
    <button
      className="btn btn-link normal-case decoration-gray-500 my-2"
      onClick={() => {
        nav('/login');
      }}
    >
      <span className="text-gray-500 font-normal">
        Already have an account? Sign in
      </span>
    </button>
  );
}
