import { ChatRow } from '@/components/ChatPane/ChatRow';
import { useSupabase } from '@/contexts/Supabase';
import { useChat } from 'ai/react';
import { scroll } from '@/utils/scroll';
import { useEffect } from 'react';
import { ChatInputForm } from '@/components/ChatInputForm';
import { AiRowLoading } from '@/components/ChatPane/AiRow';
import { Transition } from '@headlessui/react';
import { useAuth } from '@/contexts/Auth/Auth';
import { getAuthCookie } from '@/utils/getAuthCookie';

function CreateProgram() {
  const {
    conversationUuid,
    builderConversationLoading,
    suggestedResponses,
    reset,
  } = useSupabase();
  const { user } = useAuth();
  const {
    messages,
    input,
    handleInputChange,
    handleSubmit,
    reload,
    append,
    isLoading,
  } = useChat({
    id: conversationUuid,
    credentials: 'include',
    initialMessages: [
      {
        id: '0',
        role: 'assistant',
        content: `Hi! I'll help you build a new propeller program. You can say something like "make a program that builds negotiation skills" or "create a program for transitioning to engineering management.`,
      },
    ],

    api: `${process.env.REACT_APP_API_URL}/builder`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthCookie(),
    },
    body: {
      conversationUuid,
      userId: user.id,
    },
  });

  useEffect(() => {
    if (messages.length < 3) {
      scroll('center');
    } else {
      scroll('start');
    }
  }, [messages]);

  return (
    <div className="h-full">
      <div className="px-6 pt-6 pb-48">
        {messages.map((m, i) => (
          <ChatRow
            key={m.id}
            {...m}
            reload={reload}
            isLoading={isLoading}
            isLatest={i === messages.length - 1}
            hideRegen
            hideTTS
          />
        ))}
        <Transition
          as={'div'}
          show={builderConversationLoading}
          enter="transform transition duration-[400ms]"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-100 transition ease-in-out"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <AiRowLoading />
        </Transition>
      </div>
      <div className="absolute left-0 right-0 bottom-0 border-t bg-white">
        <div className="grid grid-cols-1 grid-rows-2 gap-2">
          {suggestedResponses.map((sr, i) => (
            <div
              key={sr}
              className="bg-blue-100 px-2 py-2 rounded-lg hover:bg-green-100 text-xs hidden sm:block"
              onClick={() => {
                append({ role: 'user', content: sr });
                reset();
              }}
            >
              ✨ {sr}
            </div>
          ))}
        </div>
        <div className="p-4">
          <ChatInputForm
            handleSubmit={handleSubmit}
            input={input}
            handleInputChange={handleInputChange}
            placeholder="Message Propeller Program Builder..."
          />
        </div>
      </div>
    </div>
  );
}

export default CreateProgram;
