import { useNavigate } from 'react-router-dom';

export function SignUpLink() {
  const nav = useNavigate();
  return (
    <button
      className="btn btn-sm btn-link normal-case decoration-gray-500"
      onClick={() => {
        nav('/login/sign-up');
      }}
    >
      <span className="text-gray-500 font-normal">
        Don't have an account? Sign up
      </span>
    </button>
  );
}
