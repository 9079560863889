import { memo, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { supabase } from '@/utils/supabase';
import Markdown from '@/components/Markdown';
import { RatingForm } from './RatingForm';
import { trpc } from '@/utils/trpc';
import { usePageHeading } from '@/contexts/PageHeading/PageHeading';
import { protectedPaths } from '@/routes/paths';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

function ProgramSummary() {
  const { deploymentId } = useParams();
  const [md, setMd] = useState<any>(null);
  const [loading, setIsLoading] = useState(true);

  const { setPageHeading } = usePageHeading();
  useEffect(() => {
    setPageHeading('Program Evaluation');

    return () => setPageHeading('');
  }, [setPageHeading]);

  const { data } = trpc.programDeployments.getEvaluation.useQuery({
    deploymentId: +deploymentId!,
  });

  useEffect(() => {
    if (data) {
      setMd(data);
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    const channels = supabase
      .channel('db-changes', {
        config: {
          broadcast: {
            self: false,
          },
        },
      })
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'program_evaluations',
          filter: `program_deployment_id=eq.${+deploymentId!}`,
        },
        (payload) => {
          if (payload.new['program_deployment_id'] !== +deploymentId!) return;
          setMd(payload.new['evaluation']);
          setIsLoading(false);
        }
      )
      .subscribe();

    return () => {
      channels.unsubscribe();
    };
  }, [deploymentId]);
  return (
    <div className="flex flex-col gap-6 w-full items-center divide-y h-[90vh] overflow-y-auto sm:pt-0 pt-16">
      <div className="flex gap-4 justify-between items-end w-full max-w-2xl">
        <Link
          to={protectedPaths.learningCatalog}
          className="flex flex-row gap-3"
        >
          <ArrowLeftIcon className="w-6 h-6 text-blue-800 ml-4" />
          <span>Learning Catalog</span>
        </Link>
        <div className="pt-2">
          <RatingForm />
        </div>
      </div>
      {loading && (
        <div className="flex justify-center flex-col items-center w-full p-8 gap-4">
          <span className="text-lg">
            Please wait while we generate your program evaluation.
          </span>
          <span className="loading loading-spinner text-primary"></span>
        </div>
      )}
      <div className="p-4 prose">{md && <Markdown content={md} />}</div>
    </div>
  );
}

export default memo(ProgramSummary);
