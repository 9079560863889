import { trpc } from '@/utils/trpc';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

export type FilterName = 'Tags' | 'Status' | 'Author';

export type Filter = {
  id: string;
  name: FilterName;
  options: {
    value: string;
    label: string;
  }[];
};

type LearningCatalogFilterContext = {
  sortOptions: string[];
  selectedSortOption?: string;
  setSelectedSortOption: (option: string) => void;
  filters: Filter[];
  selectedFilters: Record<FilterName, Record<string, boolean>>;
  setSelectedFilters: (
    filters: Record<FilterName, Record<string, boolean>>
  ) => void;
  tags?: { name: string }[];
};

const LearningCatalogFiltersContext =
  createContext<LearningCatalogFilterContext>({
    sortOptions: [],
    setSelectedSortOption: () => {},
    filters: [],
    setSelectedFilters: () => {},
    selectedFilters: {
      Tags: {},
      Status: {},
      Author: {},
    },
    tags: [],
  });

export function LearningCatalogFiltersProvider({
  children,
}: PropsWithChildren<{}>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedSortOption, setSelectedSortOption] =
    useState<string>('Most Popular');

  const locallyStoredFilters = JSON.parse(
    localStorage.getItem('selectedFilters') ?? 'null'
  ) as Record<FilterName, Record<string, boolean>> | null;

  const locallyStoredStatus =
    locallyStoredFilters?.Status ??
    searchParams
      .get('status')
      ?.split(',')
      .filter((s) => s !== '')
      .reduce((p, s) => ({ ...p, [s]: true }), {});

  const locallyStoredTags =
    locallyStoredFilters?.Tags ??
    searchParams
      .get('tags')
      ?.split(',')
      .filter((s) => s !== '')
      .reduce((p, s) => ({ ...p, [s]: true }), {});

  const [selectedFilters, setSelectedFilters] = useState<
    Record<FilterName, Record<string, boolean>>
  >({
    Status: locallyStoredStatus ?? {
      'not started': true,
      started: true,
      completed: true,
    },
    Tags: locallyStoredTags ?? {},
    Author: {
      me: true,
      propeller: true,
    },
  });

  const { data: tags } = trpc.programs.getTags.useQuery();

  const contextValue = useMemo(() => {
    const filters: Filter[] = [
      {
        id: 'tags',
        name: 'Tags',
        options:
          tags?.map((tag) => ({ value: tag.name, label: tag.name })) ?? [],
      },
      {
        id: 'status',
        name: 'Status',
        options: [
          { value: 'not started', label: 'Not Started' },
          { value: 'started', label: 'In Progress' },
          { value: 'completed', label: 'Completed' },
        ],
      },
      {
        id: 'author',
        name: 'Author',
        options: [
          { value: 'me', label: 'Me' },
          { value: 'propeller', label: 'Propeller' },
        ],
      },
    ];

    return {
      tags,
      sortOptions: ['Most Popular', 'Best Rating', 'Newest', 'Oldest'],
      selectedSortOption,
      setSelectedSortOption: (s: string) => {
        setSelectedSortOption(s);
        setSearchParams({ sort: s });
      },
      filters,
      selectedFilters,
      setSelectedFilters: (f: Record<FilterName, Record<string, boolean>>) => {
        setSelectedFilters(f);
        setSearchParams({
          tags: Object.entries(f.Tags)
            .filter(([k, v]) => v)
            .map(([k, v]) => k)
            .join(','),
          status: Object.entries(f.Status)
            .filter(([k, v]) => v)
            .map(([k, v]) => k)
            .join(','),
        });
        localStorage.setItem('selectedFilters', JSON.stringify(f));
      },
    };
  }, [selectedFilters, selectedSortOption, setSearchParams, tags]);

  return (
    <LearningCatalogFiltersContext.Provider value={contextValue}>
      {children}
    </LearningCatalogFiltersContext.Provider>
  );
}

export function useLearningCatalogFilters() {
  return useContext(LearningCatalogFiltersContext);
}

export default LearningCatalogFiltersContext;
