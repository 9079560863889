import { Message } from 'ai';
import { UserRow } from './UserRow';
import { AiRow } from './AiRow';

export function ChatRow(
  m: Message & {
    reload?: () => void;
    isLoading: boolean;
    isLatest: boolean;
    hideTTS?: boolean;
    hideRegen?: boolean;
  }
) {
  return (
    <>
      {m.role === 'assistant' && <AiRow {...m} />}
      {m.role === 'user' && <UserRow {...m} />}
    </>
  );
}
