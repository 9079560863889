export function Label({ label, htmlFor }: { label: string; htmlFor: string }) {
  return (
    <label
      htmlFor={htmlFor}
      className="block text-sm font-normal leading-6 text-gray-500"
    >
      {label}
    </label>
  );
}
