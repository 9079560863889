import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { supabase } from '@/utils/supabase';
import Cookies from 'js-cookie';
import { BuilderProgram } from '@/utils/types';
import { BroadcastEventName } from '@propeller/shared-types';

type ConversationStage = {
  stage: number;
  explanation: string;
};

type SupabaseContextValue = {
  setSuggestedResponses: (suggestedResponses: string[]) => void;
  suggestedResponses: string[];
  suggestedResponsesLoading: boolean;
  conversationUuid: string | undefined;
  setConversationUuid: (uuid: string | undefined) => void;
  reset: () => void;
  conversationStage: ConversationStage | null;
  setConversationStage: (stage: ConversationStage | null) => void;
  suggestedResponsesEnabled?: boolean;
  setSuggestedResponsesEnabled?: (enabled: boolean) => void;
  program?: BuilderProgram;
  setProgram: (program: BuilderProgram | undefined) => void;
  builderConversationLoading?: boolean;
  suggestedPreviewResponses: string[];
  setSuggestedPreviewResponses: (suggestedResponses: string[]) => void;
};

export const SupabaseContext = createContext<SupabaseContextValue>({
  setSuggestedResponses: () => {},
  conversationStage: null,
  suggestedResponsesLoading: false,
  reset: () => {},
  suggestedResponses: [],
  suggestedPreviewResponses: [],
  conversationUuid: undefined,
  setConversationUuid: () => {},
  suggestedResponsesEnabled: true,
  setSuggestedResponsesEnabled: () => {},
  setConversationStage: () => {},
  builderConversationLoading: false,
  setProgram: () => {},
  setSuggestedPreviewResponses: () => {},
});

export function SupabaseProvider(props: PropsWithChildren<{}>) {
  const [builderConversationLoading, setBuilderConversationLoading] =
    useState(false);
  const [suggestedResponsesEnabled, setSuggestedResponsesEnabled] = useState(
    Cookies.get('suggestedResponsesEnabled') === 'true' ||
      Cookies.get('suggestedResponsesEnabled') === undefined
  );
  const [suggestedResponsesLoading, setSuggestedResponsesLoading] =
    useState(false);
  const [conversationUuid, setConversationUuid] = useState<string | undefined>(
    undefined
  );
  const [suggestedResponses, setSuggestedResponses] = useState<string[]>([]);

  const [suggestedPreviewResponses, setSuggestedPreviewResponses] = useState<
    string[]
  >([]);

  const [conversationStage, setConversationStage] =
    useState<ConversationStage | null>(null);
  const [program, setProgram] = useState<BuilderProgram | undefined>(undefined);

  useEffect(() => {
    if (!conversationUuid) {
      return;
    }

    supabase.auth.getUser().then((user) => {
      supabase
        .channel('db-changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'builder_conversations',
            filter: `conversation_uuid=eq.${conversationUuid}`,
          },
          (payload) => {
            setProgram(payload.new as any);
          }
        )
        .subscribe();
    });
  }, [conversationUuid]);

  useEffect(() => {
    conversationUuid &&
      supabase
        .channel(conversationUuid, {
          config: {
            broadcast: {
              self: false,
            },
          },
        })
        .on(
          'broadcast',
          { event: BroadcastEventName.userSuggestionsLoading },
          (payload) => {
            setSuggestedResponsesLoading(true);
          }
        )
        .on(
          'broadcast',
          { event: BroadcastEventName.userSuggestionsNotLoading },
          (payload) => {
            setSuggestedResponsesLoading(false);
            setSuggestedResponses([]);
          }
        )
        .on(
          'broadcast',
          { event: BroadcastEventName.conversationStageDetermined },
          (payload) => {
            setConversationStage(payload['payload']);
          }
        )
        .on(
          'broadcast',
          { event: BroadcastEventName.userSuggestions },
          (payload) => {
            setSuggestedResponses(payload['payload'].suggestedResponses);
            setSuggestedResponsesLoading(false);
          }
        )
        .on(
          'broadcast',
          { event: BroadcastEventName.previewSuggestions },
          (payload) => {
            setSuggestedPreviewResponses(payload['payload'].suggestedResponses);
          }
        )
        .on(
          'broadcast',
          { event: BroadcastEventName.builderConversationLoading },
          (payload) => {
            setBuilderConversationLoading(true);
          }
        )
        .on(
          'broadcast',
          { event: BroadcastEventName.builderConversationNotLoading },
          (payload) => {
            setBuilderConversationLoading(false);
          }
        )
        .subscribe();
  }, [conversationUuid]);

  const contextValue = useMemo(() => {
    const resetSuggestedResponses = () => {
      setSuggestedResponses([]);
    };
    return {
      program,
      conversationStage,
      setSuggestedResponses,
      reset: resetSuggestedResponses,
      suggestedResponses,
      conversationUuid,
      setConversationUuid,
      suggestedResponsesLoading,
      suggestedResponsesEnabled,
      setSuggestedResponsesEnabled: (enabled: boolean) => {
        setSuggestedResponsesEnabled(enabled);
        Cookies.set('suggestedResponsesEnabled', enabled.toString());
      },
      setConversationStage,
      builderConversationLoading,
      suggestedPreviewResponses,
      setProgram,
      setSuggestedPreviewResponses,
    };
  }, [
    suggestedResponsesEnabled,
    conversationStage,
    suggestedResponses,
    conversationUuid,
    suggestedResponsesLoading,
    program,
    builderConversationLoading,
    suggestedPreviewResponses,
    setProgram,
    setSuggestedPreviewResponses,
  ]);

  return <SupabaseContext.Provider {...props} value={contextValue} />;
}

export function useSupabase() {
  return useContext(SupabaseContext);
}

export default SupabaseContext;
