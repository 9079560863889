import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { ComponentPropsWithoutRef, Fragment, ReactElement } from 'react';

function ThreeEllipses() {
  return (
    <div className="w-[54px] h-6 justify-center items-center gap-2.5 inline-flex">
      <div className="justify-start items-start gap-1 inline-flex">
        <div className="w-1 h-1 bg-neutral-800 rounded-full" />
        <div className="w-1 h-1 bg-neutral-800 rounded-full" />
        <div className="w-1 h-1 bg-neutral-800 rounded-full" />
      </div>
    </div>
  );
}

export type ActionButtonAction = {
  ActionElement: (active: boolean) => ReactElement;
};

export function ActionButton({
  actions,
  className,
}: {
  actions: ActionButtonAction[];
} & ComponentPropsWithoutRef<'div'>) {
  return (
    <Menu as="div" className={clsx('relative inline-block', className)}>
      <Menu.Button className="z-0 inline-flex w-full justify-center rounded-md bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        <ThreeEllipses />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-10 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1 ">
            {actions.map((action, i) => (
              <Menu.Item key={i}>
                {({ active }) => action.ActionElement(active)}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
