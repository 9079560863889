import PreviewProgramPane from './Panes/PreviewProgramPane';
import { usePageHeading } from '@/contexts/PageHeading/PageHeading';
import { useEffectOnce } from 'usehooks-ts';
import { generateUUID } from '@/utils/generateUUID';
import { useSupabase } from '@/contexts/Supabase';
import { memo, useEffect } from 'react';
import BuildProgramPane from './Panes/BuildProgram/BuildProgramPane';

function ProgramBuilder() {
  const { setPageHeading, setAdditionalPageHeadingButtons } = usePageHeading();
  const {
    setConversationUuid,
    conversationUuid,
    reset,
    setProgram,
    setSuggestedPreviewResponses,
  } = useSupabase();

  useEffect(() => {
    const uuid = generateUUID();
    if (!conversationUuid) {
      setConversationUuid(uuid);
    }
  }, [conversationUuid, setConversationUuid]);

  useEffectOnce(() => {
    setPageHeading('Program Builder');

    return () => {
      setAdditionalPageHeadingButtons([]);
      setPageHeading('');
      reset();
      setConversationUuid(undefined);
      setProgram(undefined);
      setSuggestedPreviewResponses([]);
    };
  });

  return (
    <div className="h-[calc(100vh-4rem)] grid grid-cols-2 divide-x">
      <div className="relative">
        <BuildProgramPane />
      </div>
      <div className="relative">
        <PreviewProgramPane />
      </div>
    </div>
  );
}

export default memo(ProgramBuilder);
