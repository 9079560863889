import ReactMarkdown from 'react-markdown';

function Markdown({ content }: { content: string | null | undefined }) {
  if (!content) {
    return null;
  }
  return (
    <ReactMarkdown
      components={{
        ol: ({ node, ordered, ...props }) => (
          <ol className="ml-8 list-outside list-decimal" {...props} />
        ),
        li: ({ node, ordered, ...props }) => (
          <li className="sm:text-base text-sm mb-6" {...props} />
        ),
        p: ({ node, ...props }) => (
          <p className="sm:text-base text-sm mb-3" {...props} />
        ),
      }}
      // eslint-disable-next-line react/no-children-prop
      children={content}
    />
  );
}

export default Markdown;
