import { supabase } from '@/utils/supabase';
import { Session } from '@supabase/supabase-js';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Cookies from 'js-cookie';
import { paths } from '@/routes/paths';
import { useUnleashContext } from '@unleash/proxy-client-react';
import userflow from 'userflow.js';
import { datadogRum } from '@datadog/browser-rum';

type AuthContextValue = {
  session: Session | null;
  setSession: (session: Session | null) => void;
  isAuthenticated: boolean;
  logout: () => void;
  user: {
    id: string;
    initials: string;
    email: string;
  };
};

export const AuthContext = createContext<AuthContextValue>({
  logout: () => {},
  session: null,
  setSession: () => {},
  isAuthenticated: false,
  user: {
    id: '',
    initials: '',
    email: '',
  },
});

export function AuthProvider(props: PropsWithChildren<{}>) {
  const [session, setSession] = useState<Session | null>(null);
  const updateContext = useUnleashContext();

  useEffect(() => {
    supabase.auth
      .getSession()
      .then(({ data: { session } }) => {
        setSession(session);
        session?.access_token &&
          Cookies.set('supabase_session', JSON.stringify(session));
        Cookies.set(
          'isAuthenticated',
          Boolean(session?.access_token).toString()
        );
        session &&
          updateContext({
            userId: session.user.id,
          });
        session &&
          datadogRum.setUser({
            id: session.user.id,
            name: session.user.user_metadata.name,
            email: session.user.email,
          });
        if (session && process.env.REACT_APP_USERFLOW_TOKEN) {
          userflow.identify(session.user.id, {
            name: session.user.user_metadata.name,
          });
        } else if (process.env.REACT_APP_USERFLOW_TOKEN) {
          userflow.identifyAnonymous();
        }
      })
      .catch((err) => {
        console.error(err);
        window.location.href = paths.login.root;
      });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (_event === 'SIGNED_OUT') {
        Cookies.remove('supabase_session');
        Cookies.set('isAuthenticated', 'false');
      } else if (_event === 'SIGNED_IN' && session) {
        const { access_token, refresh_token } = session;
        Cookies.set(
          'supabase_session',
          JSON.stringify({
            access_token,
            refresh_token,
          })
        );
        Cookies.set('isAuthenticated', 'true');
        const from = sessionStorage.getItem('from');
        if (from) {
          sessionStorage.removeItem('from');
          window.location.href = from;
        }
      } else if (_event === 'TOKEN_REFRESHED') {
        Cookies.set('supabase_session', JSON.stringify(session));
        Cookies.set('isAuthenticated', 'true');
      } else if (_event === 'INITIAL_SESSION' && !session) {
        Cookies.set('isAuthenticated', 'false');
        Cookies.remove('supabase_session');
        if (!window.location.pathname.includes(paths.login.root)) {
          if (window.location.pathname === '/') {
            window.location.href = paths.login.root;
          } else {
            window.location.href = `${paths.login.root}?from=${window.location.pathname}`;
          }
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [updateContext]);

  const contextValue = useMemo(() => {
    const logout = async () => {
      await supabase.auth.signOut();
      window.location.href = paths.login.root;
    };

    return {
      logout,
      session,
      setSession,
      isAuthenticated: JSON.parse(
        Cookies.get('isAuthenticated') ?? 'false'
      ) as any as boolean,
      user: {
        id: session?.user.id ?? '',
        initials: session?.user.user_metadata?.name
          ?.split(' ')
          .map((n: string) => n[0]),
        email: session?.user.email ?? '',
      },
    };
  }, [session, setSession]);

  return <AuthContext.Provider {...props} value={contextValue} />;
}

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthContext;
