import { Label } from '@/pages/Login/components/Label';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { InputErrorLabel } from '@/pages/Login/components/InputErrorLabel';
import clsx from 'clsx';

export const BaseTextAreaInputStyles = `textarea textarea-bordered h-32 w-full`;

export const ConditionalTextAreaStyles = <T,>(
  errors: FormikErrors<T>,
  touched: FormikTouched<T>,
  fieldName: string
) => ({
  'textarea-error': errors[fieldName] && touched[fieldName],
  'ring-1 ring-gray-300 ring-inset shadow-sm border-0':
    !errors[fieldName] || !touched[fieldName],
});
export function TextAreaField<T>({
  name,
  errors,
  touched,
  placeholder,
  label,
  children,
  className,
  onBlur,
}: {
  className?: string;
  label: string;
  name: string;
  placeholder?: string;
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
  children?: React.ReactNode;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
}) {
  return (
    <Field name={name}>
      {({ field }) => (
        <div className="relative">
          <Label label={label} htmlFor={field.name} />
          <textarea
            {...field}
            placeholder={placeholder}
            className={clsx(
              BaseTextAreaInputStyles,
              ConditionalTextAreaStyles(errors, touched, field.name),
              className
            )}
            onBlur={onBlur}
          />
          {children}
          <InputErrorLabel
            errors={errors}
            touched={touched}
            name={field.name}
          />
        </div>
      )}
    </Field>
  );
}
