import { Message } from 'ai';
import Markdown from '@/components/Markdown';
import { ReactComponent as PropellerLogo } from '@/assets/icons/logo-propeller.svg';
import { useEffect, useRef, useState } from 'react';
import { PauseCircleIcon, PlayIcon } from '@heroicons/react/24/outline';
import { useFlag } from '@unleash/proxy-client-react';
import clsx from 'clsx';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

const rolePlayingStartToken = 'AS_ROLEPLAYER:';

function TTSButton({ content }: { content: string }) {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const ttsEnabled = useFlag('text-to-speech');
  const [isPlaying, setIsPlaying] = useState(false);

  const handleAudioEnd = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.addEventListener('ended', handleAudioEnd);
    }
    return () => {
      if (audioElement) {
        audioElement.removeEventListener('ended', handleAudioEnd);
      }
    };
  }, []);

  return (
    <>
      <button
        className={clsx({
          hidden: !ttsEnabled,
        })}
        onClick={() => {
          if (!ttsEnabled) {
            return;
          }

          if (!audioRef.current) {
            return;
          }

          if (!isPlaying && !audioRef.current.src) {
            const base64encodedContent = btoa(content);
            audioRef.current.src = `${process.env.REACT_APP_API_URL}/completion?m=${base64encodedContent}`;
            audioRef.current.play();
            setIsPlaying(true);
          } else if (!isPlaying && audioRef.current.src) {
            audioRef.current.play();
            setIsPlaying(true);
          } else if (isPlaying && audioRef.current.src) {
            audioRef.current.pause();
            setIsPlaying(false);
          }
        }}
      >
        {isPlaying ? (
          <PauseCircleIcon className="w-4 h-4" />
        ) : (
          <PlayIcon className="w-4 h-4" />
        )}
      </button>
      <audio ref={audioRef}></audio>
    </>
  );
}

function RegenerateButton({ reload }: { reload?: () => void }) {
  return (
    <button
      className="text-blue-midnight hover:text-blue-midnight-dark hover:bg-green-100 rounded-full w-10 h-10 flex items-center justify-center ml"
      onClick={() => {
        reload?.();
      }}
    >
      <ArrowPathIcon className="w-4 h-4" />
    </button>
  );
}

function ActionRow({
  content,
  reload,
  isLoading,
  isLatest,
  hideTTS,
  hideRegenerate,
}: {
  content: string;
  reload?: () => void;
  isLoading: boolean;
  isLatest: boolean;
  hideTTS?: boolean;
  hideRegenerate?: boolean;
}) {
  if (isLoading || !isLatest) {
    return <></>;
  }
  return (
    <div className="flex justify-between items-center">
      {!hideTTS && <TTSButton content={content} />}
      {!hideRegenerate && <RegenerateButton reload={reload} />}
    </div>
  );
}

export function AiRowLoading({ className }: { className?: string }) {
  return (
    <div className={clsx('chat chat-start', className)}>
      <div className="placeholder avatar mt-auto">
        <div className="bg-neutral-focus text-neutral-content rounded-full w-8 p-1.5">
          <PropellerLogo />
        </div>
      </div>
      <div className="chat-bubble bg-[#E6FDDF] text-blue-midnight px-5 py-0.5">
        <span className="loading loading-dots loading-lg"></span>
      </div>
    </div>
  );
}

export function AiRow(
  m: Message & {
    reload?: () => void;
    isLoading: boolean;
    isLatest: boolean;
    hideTTS?: boolean;
    hideRegen?: boolean;
  }
) {
  const isRolePlaying = m.content.includes('AS_');
  const startOfRolePlaying = m.content.indexOf(rolePlayingStartToken);
  const beforeRolePlaying = m.content.substring(0, startOfRolePlaying);
  const afterRolePlaying = m.content
    .substring(
      startOfRolePlaying + rolePlayingStartToken.length + 1,
      m.content.length
    )
    .replaceAll('/n', '');

  return (
    <div className="chat chat-start">
      <div className="placeholder avatar mt-auto">
        <div className="bg-neutral text-neutral-content rounded-full w-8 p-1.5">
          <PropellerLogo />
        </div>
      </div>
      {isRolePlaying && (
        <div>
          {beforeRolePlaying.length > 10 && (
            <div className="chat-bubble bg-[#E6FDDF] text-blue-midnight mb-2">
              <Markdown content={beforeRolePlaying} />
              <ActionRow
                content={beforeRolePlaying}
                reload={m.reload}
                isLoading={m.isLoading}
                isLatest={m.isLatest}
                hideRegenerate={m.hideRegen}
                hideTTS={m.hideTTS}
              />
            </div>
          )}
          <div className="chat-bubble bg-red-100 text-blue-midnight font-bold">
            <span>{afterRolePlaying}</span>
            <ActionRow
              isLatest={m.isLatest}
              content={afterRolePlaying}
              reload={m.reload}
              isLoading={m.isLoading}
              hideRegenerate={m.hideRegen}
              hideTTS={m.hideTTS}
            />
          </div>
        </div>
      )}
      {!isRolePlaying && (
        <div className="chat-bubble bg-[#E6FDDF] text-blue-midnight">
          <Markdown content={m.content.replace(rolePlayingStartToken, '')} />
          <ActionRow
            isLatest={m.isLatest}
            content={m.content.replace(rolePlayingStartToken, '')}
            reload={m.reload}
            isLoading={m.isLoading}
            hideRegenerate={m.hideRegen}
            hideTTS={m.hideTTS}
          />
        </div>
      )}
    </div>
  );
}
