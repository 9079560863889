import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

type PageHeadingContextValue = {
  pageHeading: string;
  pageSubHeading?: string;
  setPageHeading: (pageHeading: string) => void;
  setPageSubHeading: (pageSubHeading: string) => void;
  reset: () => void;
  additionalPageHeadingButtons?: React.ReactElement[];
  setAdditionalPageHeadingButtons: (buttons: React.ReactElement[]) => void;
};

export const PageHeading = createContext<PageHeadingContextValue>({
  pageHeading: '',
  pageSubHeading: '',
  setPageHeading: () => {},
  setPageSubHeading: () => {},
  reset: () => {},
  setAdditionalPageHeadingButtons: () => {},
});

export function PageHeadingProvider(props: PropsWithChildren<{}>) {
  const [pageHeading, setPageHeading] = useState<string>('');
  const [pageSubHeading, setPageSubHeading] = useState<string>('');
  const [additionalPageHeadingButtons, setAdditionalPageHeadingButtons] =
    useState<React.ReactElement[]>([]);

  const reset = () => {
    setPageHeading('');
    setPageSubHeading('');
    setAdditionalPageHeadingButtons([]);
  };

  const contextValue = useMemo(() => {
    return {
      pageHeading,
      pageSubHeading,
      setPageHeading,
      setPageSubHeading,
      reset,
      additionalPageHeadingButtons,
      setAdditionalPageHeadingButtons,
    };
  }, [additionalPageHeadingButtons, pageHeading, pageSubHeading]);

  return <PageHeading.Provider {...props} value={contextValue} />;
}

export function usePageHeading() {
  return useContext(PageHeading);
}

export default PageHeading;
