import { CogIcon } from '@heroicons/react/24/outline';
import { useSupabase } from '@/contexts/Supabase';
import { Switch } from '@headlessui/react';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

export function ChatSettingsDropdown() {
  const { suggestedResponsesEnabled, setSuggestedResponsesEnabled } =
    useSupabase();
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="items-center flex w-full justify-center rounded-md px-2 py-2 ml-1 text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 hover:bg-gray-200">
          <CogIcon className="h-7 w-7 " aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2  origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({ active }) => (
                <div className="flex p-4 gap-4 items-center">
                  <Switch
                    checked={suggestedResponsesEnabled}
                    onChange={setSuggestedResponsesEnabled}
                    className={`${
                      suggestedResponsesEnabled ? 'bg-teal-900' : 'bg-gray-200'
                    }
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${
                        suggestedResponsesEnabled
                          ? 'translate-x-9'
                          : 'translate-x-0'
                      }
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                  <span className="text-sm w-48">Show Suggested Responses</span>
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
