import { ChatRequestOptions } from 'ai';

export function ChatInputForm({
  handleSubmit,
  input,
  handleInputChange,
  placeholder,
}: {
  input: string | undefined;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  handleSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    chatRequestOptions?: ChatRequestOptions | undefined
  ) => void;
}) {
  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
      className="border border-slate-300 flex flex-grow rounded-md bg-white justify-between items-center p-3"
    >
      <input
        className="w-full outline-none bg-inherit"
        value={input ?? ''}
        placeholder={placeholder}
        onChange={handleInputChange}
      />
    </form>
  );
}
