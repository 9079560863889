import { useState } from 'react';
import { SignInLink } from '../components/SignInLink';
import { supabase } from '@/utils/supabase';
import { publicPaths } from '@/routes/paths';
import { Form, Formik } from 'formik';
import { TextField } from '../components/TextField';
import toast from 'react-hot-toast';

export function ForgotPasswordView() {
  const [message, setMessage] = useState('');
  return (
    <>
      {message && <div className="text-gray-500 my-12">{message}</div>}
      {!message && (
        <Formik
          initialValues={{
            email: '',
          }}
          onSubmit={async (values) => {
            const { error } = await supabase.auth.resetPasswordForEmail(
              values.email,
              {
                redirectTo: `${window.location.origin}${publicPaths.login.updatePassword}`,
              }
            );
            if (error) {
              console.error(error);
              toast.error(error.message);
            } else {
              setMessage('Check your email for the password reset link');
            }
          }}
        >
          {({ values, isValid, ...rest }) => (
            <Form className="flex flex-col mt-5 gap-1">
              <TextField
                label="Email address"
                name="email"
                placeholder="Your email address"
                autocomplete="email"
                type="email"
                {...rest}
              />
              <div className="flex flex-col">
                <button type="submit" className="btn btn-primary btn-block">
                  Send reset password instructions
                </button>
                <SignInLink />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
