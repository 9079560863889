import { LearningCatalog as LearningCatalogType } from '@/utils/trpc';
import { LearningCatalogCard } from './LearningCatalogCard';
import { shapes } from './shapes';

export function LearningCatalogCards({
  learningCatalog,
}: {
  learningCatalog?: LearningCatalogType[];
}) {
  return (
    <ul className="p-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 ">
      {learningCatalog?.map((program, i) => (
        <li key={program.id} className="col-span-1 divide-y divide-gray-200">
          <LearningCatalogCard program={program}>
            {shapes[i % Object.keys(shapes).length]}
          </LearningCatalogCard>
        </li>
      ))}
    </ul>
  );
}
