import { FormikErrors, FormikTouched } from 'formik';

export const InputErrorLabel = <T,>({
  errors,
  touched,
  name,
}: {
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
  name: string;
}) => (
  <div className="label h-6">
    <span className="label-text-alt" />
    <span className="label-text-alt">
      {errors[name] && touched[name] && errors[name]}
    </span>
  </div>
);
