import { createTRPCReact } from '@trpc/react-query';
import type { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '@propeller/api';

export type RouterOutput = inferRouterOutputs<AppRouter>;

export type LearningCatalog =
  RouterOutput['programs']['getLearningCatalog'][number];

export const trpc = createTRPCReact<AppRouter>();
