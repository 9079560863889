import { ComponentPropsWithoutRef } from 'react';

export function EmptyState({
  title,
  description,
}: ComponentPropsWithoutRef<'div'> & {
  title: string;
  description: string;
}) {
  return (
    <div className="text-center">
      <h3 className="mt-2 text-sm font-semibold text-gray-900">{title} </h3>
      <p className="mt-1 text-sm text-gray-500">{description}</p>
    </div>
  );
}
